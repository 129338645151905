import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { X, Menu } from "lucide-react";

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close menu when clicking a link
  const handleLinkClick = () => {
    setIsOpen(false);
    // Scroll to top is handled by HashLink
  };

  return (
    <div className="relative z-50">
      {/* Burger Button */}
      <button
        onClick={toggleMenu}
        className="p-2 focus:outline-none fixed top-4 right-4 bg-white rounded-md shadow-md"
        aria-label="Toggle menu"
      >
        {isOpen ? (
          <X className="w-6 h-6 text-gray-800" />
        ) : (
          <Menu className="w-6 h-6 text-gray-800" />
        )}
      </button>

      {/* Menu Content */}
      {isOpen && (
        <div className="fixed top-16 right-4 w-64 bg-white rounded-lg shadow-xl z-50 border border-gray-200">
          <nav className="p-4">
            <ul className="space-y-2">
              <li>
                <HashLink
                  smooth
                  to="/#"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 rounded"
                  onClick={handleLinkClick}
                >
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/blog#"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 rounded"
                  onClick={handleLinkClick}
                >
                  Blog
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/basketball#"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 rounded"
                  onClick={handleLinkClick}
                >
                  Basketball Camp
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/sponsorships#"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 rounded"
                  onClick={handleLinkClick}
                >
                  Sponsorships
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/privacy-policy#"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 rounded"
                  onClick={handleLinkClick}
                >
                  Privacy Policy
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/termsofservices#"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 rounded"
                  onClick={handleLinkClick}
                >
                  Terms of Service
                </HashLink>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default BurgerMenu;
