import React from "react";
import { blogPosts } from "../blog/post";
import BurgerMenu from "../components/BurgerMenu";

const BlogSection = () => {
  const handlePostClick = (slug) => {
    window.location.href = `/blog/${slug}`;
  };

  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-4 py-4 flex justify-end">
        <BurgerMenu />
      </div>
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Latest Insights</h2>
          <p className="text-lg text-gray-600">
            Stay updated with the latest trends and strategies in the creator
            economy
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <div
              key={post.id}
              onClick={() => handlePostClick(post.slug)}
              className="cursor-pointer bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300"
            >
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <time className="text-sm text-gray-500 mb-2 block">
                  {new Date(post.date).toLocaleDateString()}
                </time>
                <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
                <p className="text-gray-600 line-clamp-3">{post.excerpt}</p>
                <div className="mt-4 text-blue-600 font-medium">
                  Read More →
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
