// Confirmation.jsx - Registration confirmation page
import React, { useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";

const Confirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const formData = location.state?.formData;

  // If no form data, redirect back to home
  useEffect(() => {
    if (!formData) {
      navigate("/");
    }
  }, [formData, navigate]);

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
  };

  if (!formData) {
    return null; // Will redirect due to useEffect
  }

  // Generate a random confirmation number
  const confirmationNumber = Math.floor(100000 + Math.random() * 900000);

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
          <div className="bg-green-600 px-6 py-4">
            <h1 className="text-3xl font-bold text-white">
              Registration Complete!
            </h1>
          </div>

          <div className="p-8">
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              className="text-center"
            >
              <div className="w-16 h-16 bg-green-100 text-green-600 rounded-full flex items-center justify-center mx-auto mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>

              <h2 className="text-2xl font-bold text-gray-800 mb-2">
                Thank You for Registering!
              </h2>
              <p className="text-lg text-gray-600 mb-6">
                {formData.playerFirstName} is registered for Donnavan Kirk's
                Youth Basketball Camp.
              </p>

              <div className="bg-gray-100 p-6 rounded-lg mb-8 inline-block">
                <p className="text-sm text-gray-600 mb-1">
                  Confirmation Number
                </p>
                <p className="text-2xl font-bold text-gray-800">
                  {confirmationNumber}
                </p>
              </div>

              <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6 text-left">
                <div className="flex">
                  <div className="flex-shrink-0 text-blue-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-blue-800">
                      A confirmation email has been sent to{" "}
                      <strong>{formData.email}</strong> with all the camp
                      details.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mb-8 text-left">
                <h3 className="text-lg font-bold text-gray-800 mb-3">
                  Camp Details
                </h3>
                <div className="bg-gray-100 p-4 rounded-lg">
                  <p>
                    <strong>Location:</strong> Detroit Country Day
                  </p>
                  <p>
                    <strong>Dates:</strong> A week in July (Monday - Friday)
                  </p>
                  <p>
                    <strong>Time:</strong> 9:00 AM - 3:00 PM
                  </p>
                  <p>
                    <strong>What to Bring:</strong> Basketball shoes, water
                    bottle, and a great attitude
                  </p>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <Link
                  to="/"
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                >
                  Return to Home
                </Link>
                <Link
                  to="/basketballcamp"
                  className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                >
                  View Camp Details
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
