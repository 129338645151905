import React from "react";
import PropTypes from "prop-types";

const ProfileEmptyState = ({ category, onCreatePress }) => {
  const getContent = () => {
    switch (category) {
      case "Portfolio":
        return {
          icon: "🎨",
          title: "Start Your Portfolio",
          subtitle: "Showcase your best work and creative projects",
          buttonText: "Create First Post",
        };
      case "Ideas":
        return {
          icon: "💡",
          title: "Share Your Ideas",
          subtitle: "Document your creative concepts and inspirations",
          buttonText: "Add First Idea",
        };
      case "Moodboard":
        return {
          icon: "🎯",
          title: "Create a Moodboard",
          subtitle: "Collect and organize visual inspiration",
          buttonText: "Start Moodboard",
        };
      default:
        return {
          icon: "❓",
          title: "No Content",
          subtitle: "Please select a valid category",
          buttonText: "Try Again",
        };
    }
  };

  const content = getContent();

  return (
    <div style={styles.container}>
      <div style={styles.icon}>{content.icon}</div>
      <h2 style={styles.title}>{content.title}</h2>
      <p style={styles.subtitle}>{content.subtitle}</p>
      <button style={styles.button} onClick={onCreatePress}>
        <img
          src="/path/to/plus-icon.svg"
          alt="Plus Icon"
          style={styles.plusIcon}
        />
        {content.buttonText}
      </button>
    </div>
  );
};

ProfileEmptyState.propTypes = {
  category: PropTypes.string.isRequired,
  onCreatePress: PropTypes.func.isRequired,
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "400px",
    padding: "20px",
    textAlign: "center",
  },
  icon: {
    fontSize: "48px",
    marginBottom: "16px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#1a1a1a",
    marginBottom: "8px",
    fontFamily: "'Oswald', sans-serif",
  },
  subtitle: {
    fontSize: "16px",
    color: "#666666",
    marginBottom: "24px",
    lineHeight: "24px",
    fontFamily: "'Roboto', sans-serif",
  },
  button: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#6366F1",
    color: "white",
    padding: "12px 20px",
    borderRadius: "12px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "'Oswald', sans-serif",
    boxShadow: "0 2px 3px rgba(0, 0, 0, 0.2)",
  },
  plusIcon: {
    width: "20px",
    height: "20px",
    marginRight: "8px",
    filter: "invert(1)", // Makes the icon white
  },
};

export default ProfileEmptyState;
