// CheckoutForm.jsx
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";

// Using TEST publishable key for development environment
const stripePromise = loadStripe(
  "pk_live_51PDgmKRotvaadvKDcnKp3zgkkbtvnLlIT0z0xgbFACtNUusf0IT3wfnnw8VQH5fsclUHh5hdlwFHg6zN0ZKrOBcg00jR7L7JtF"
);

const CheckoutForm = ({ formData, setPaymentStatus, registrationId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const functions = getFunctions();

  useEffect(() => {
    // Calculate the total cost based on the number of players
    const costPerPlayer = 525;
    const calculatedTotal = formData.players.length * costPerPlayer;
    setTotalCost(calculatedTotal);

    // Debug the formData to ensure it has all required fields
    console.log(
      "FormData being sent:",
      JSON.stringify({
        email: formData.email,
        parentFirstName: formData.parentFirstName,
        parentLastName: formData.parentLastName,
        players: formData.players.length,
      })
    );

    // Create a payment intent using the Firebase Cloud Function
    // Replace the httpsCallable code with this:
    const createPaymentIntent = async () => {
      try {
        // Create simplified version of form data
        const simplifiedFormData = {
          email: formData.email,
          parentFirstName: formData.parentFirstName,
          parentLastName: formData.parentLastName,
          players: formData.players.length,
        };

        // Create payload
        const payload = {
          amount: calculatedTotal,
          formData: simplifiedFormData,
          registrationId: registrationId,
        };

        // Make direct HTTP request
        const response = await fetch(
          "https://us-central1-ccfirebasetest.cloudfunctions.net/createBasketballCampPayment",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        const data = await response.json();
        setClientSecret(data.paymentIntent);
        console.log("Payment intent created successfully");
      } catch (error) {
        console.error("Error creating payment intent:", error);
        setError("Failed to initialize payment. Please try again later.");
      }
    };

    if (stripe) {
      createPaymentIntent();
    }
  }, [formData, functions, stripe, registrationId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      setError("Payment processing not ready. Please wait or reload the page.");
      return;
    }

    setProcessing(true);

    // Confirm the card payment using Stripe
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${formData.parentFirstName} ${formData.parentLastName}`,
          email: formData.email,
        },
      },
    });

    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setSucceeded(true);
      setProcessing(false);
      setPaymentStatus("success");

      // Redirect to confirmation page
      navigate("/confirmation", { state: { formData, registrationId } });
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="mt-8">
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Card Details
        </label>
        <div className="p-3 border rounded shadow-sm bg-white">
          <CardElement options={cardElementOptions} />
        </div>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">{error}</div>
      )}

      <div className="flex justify-between">
        <Link
          to="/register"
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
        >
          Back
        </Link>
        <button
          type="submit"
          disabled={processing || !stripe || !elements || !clientSecret}
          className={`${
            processing || !stripe || !clientSecret
              ? "bg-gray-400"
              : "bg-orange-500 hover:bg-orange-600"
          } text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline`}
        >
          {processing ? "Processing..." : `Pay $${totalCost}`}
        </button>
      </div>
    </form>
  );
};

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const formData = location.state?.formData;
  const registrationId = location.state?.registrationId; // Get registrationId from state

  // If no form data, redirect back to registration
  useEffect(() => {
    if (!formData || !registrationId) {
      navigate("/register");
    } else {
      // Debug check to ensure formData has the required fields
      console.log("Payment received formData:", {
        email: formData.email,
        parentName: `${formData.parentFirstName} ${formData.parentLastName}`,
        playerCount: formData.players?.length || 0,
      });
    }
  }, [formData, navigate, registrationId]);

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
  };

  if (!formData || !registrationId) {
    return null; // Will redirect due to useEffect
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
          <div className="bg-gradient-to-r from-orange-500 to-red-600 px-6 py-4">
            <Link to="/register" className="text-white flex items-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back to Registration
            </Link>
            <h1 className="text-3xl font-bold text-white">Payment</h1>
          </div>

          <div className="p-8">
            <motion.div initial="hidden" animate="visible" variants={fadeIn}>
              <h2 className="text-2xl font-bold text-gray-800 mb-6">
                Complete Your Registration
              </h2>

              <div className="mb-8">
                <h3 className="text-lg font-bold mb-3">Order Summary</h3>
                <div className="bg-gray-100 p-4 rounded-lg">
                  <div className="flex justify-between mb-2">
                    <span>Donnavan Kirk's Youth Basketball Camp</span>
                    <span>$525.00</span>
                  </div>
                  <div className="flex justify-between font-bold pt-2 border-t border-gray-300">
                    <span>Total</span>
                    <span>${formData.players.length * 525}</span>
                  </div>
                </div>
              </div>

              {/* Display information for all players */}
              {formData.players.map((player, index) => (
                <div key={index} className="mb-6">
                  <h3 className="text-lg font-bold mb-3">
                    Participant #{index + 1}
                  </h3>
                  <div className="bg-gray-100 p-4 rounded-lg">
                    <p className="mb-1">
                      <strong>Name:</strong> {player.playerFirstName}{" "}
                      {player.playerLastName}
                    </p>
                    <p>
                      <strong>Age:</strong> {player.age}
                    </p>
                  </div>
                </div>
              ))}

              <Elements stripe={stripePromise}>
                <CheckoutForm
                  formData={formData}
                  setPaymentStatus={setPaymentStatus}
                  registrationId={registrationId}
                />
              </Elements>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
