// src/app/(tabs)/Profile.web.jsx
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/FirebaseGlobalProvider";
import VideoCard from "../components/mediaComponents/VideoCard.Web";
import ProfileEmptyState from "../components/ProfileEmptyState.web";
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";

const CACHE_KEY_POSTS = "profile_posts_cache";
const CACHE_KEY_FOLLOWERS = "profile_followers_cache";
const CACHE_EXPIRY = 30 * 60 * 1000; // 30 minutes

const Profile = () => {
  const navigate = useNavigate();
  const { user, setUser, setIsLoggedIn } = useGlobalContext();
  const db = getFirestore();
  const auth = getAuth();

  const [posts, setPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Portfolio");
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGloballyMuted, setIsGloballyMuted] = useState(true);
  const [followerCount, setFollowerCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);

  const categories = ["Portfolio", "Ideas", "Moodboard"];

  useEffect(() => {
    if (user) {
      loadInitialData();
    }
  }, [user]);

  const loadInitialData = async () => {
    try {
      setIsLoading(true);
      const [postsData, followersCount] = await Promise.all([
        fetchUserPosts(),
        fetchFollowerCount(),
      ]);

      setPosts(postsData);
      setFollowerCount(followersCount);
    } catch (error) {
      console.error("Error loading initial data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserPosts = async (isInitial = true) => {
    try {
      if (!user) return [];

      let postsQuery = query(
        collection(db, "videos"),
        where("creator", "==", user.$id),
        orderBy("createdAt", "desc"),
        limit(10)
      );

      if (!isInitial && lastVisible) {
        postsQuery = query(postsQuery, startAfter(lastVisible));
      }

      const snapshot = await getDocs(postsQuery);

      if (snapshot.empty) {
        setHasMore(false);
        return [];
      }

      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

      const postsData = await Promise.all(
        snapshot.docs.map(async (document) => {
          const postData = document.data();
          const creatorDoc = await getDoc(doc(db, "users", postData.creator));

          return {
            $id: document.id,
            ...postData,
            creator: {
              $id: postData.creator,
              ...creatorDoc.data(),
            },
          };
        })
      );

      return postsData;
    } catch (error) {
      console.error("Error fetching user posts:", error);
      return [];
    }
  };

  const fetchFollowerCount = async () => {
    try {
      if (!user?.$id) return 0;
      const followersSnapshot = await getDocs(
        collection(db, "users", user.$id, "followers")
      );
      return followersSnapshot.size;
    } catch (error) {
      console.error("Error fetching follower count:", error);
      return 0;
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      await deleteDoc(doc(db, "videos", postId));
      setPosts((prevPosts) => prevPosts.filter((post) => post.$id !== postId));
      alert("Post deleted successfully");
    } catch (error) {
      console.error("Delete error:", error);
      alert("Failed to delete post");
    }
  };

  const updatePostDetails = async (postId, updates) => {
    try {
      await updateDoc(doc(db, "videos", postId), updates);
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.$id === postId ? { ...post, ...updates } : post
        )
      );
      return true;
    } catch (error) {
      console.error("Update error:", error);
      return false;
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsLoggedIn(false);
      navigate("/auth");
    } catch (error) {
      console.error("Logout error:", error);
      alert("Failed to logout. Please try again.");
    }
  };

  const loadMore = async () => {
    if (!hasMore || isLoading) return;

    try {
      const newPosts = await fetchUserPosts(false);
      if (newPosts.length > 0) {
        setPosts((prev) => [...prev, ...newPosts]);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading more posts:", error);
    }
  };

  // Intersection Observer for infinite scroll
  const observerRef = useRef(null);
  const loadingTriggerRef = useCallback(
    (node) => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [hasMore]
  );

  const filteredPosts = posts.filter(
    (post) => post.category === selectedCategory
  );

  return (
    <div style={styles.container}>
      {/* Header Section */}
      <div style={styles.header}>
        <div style={styles.headerContent}>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            style={styles.menuButton}
          >
            ☰
          </button>
          <button
            style={styles.aiButton}
            onClick={() => navigate("/image-generator")}
          >
            <span style={styles.aiButtonIcon}>🤖</span>
            <span style={styles.aiButtonText}>ASK AI</span>
          </button>
        </div>
      </div>

      {/* Profile Section */}
      <div style={styles.profileSection}>
        <div style={styles.avatarContainer}>
          <img
            src={user?.avatar || "/default-avatar.png"}
            alt={user?.username}
            style={styles.avatar}
          />
        </div>
        <h2 style={styles.username}>{user?.username}</h2>

        <div style={styles.statsContainer}>
          <div style={styles.statItem}>
            <span style={styles.statNumber}>{posts.length}</span>
            <span style={styles.statLabel}>Posts</span>
          </div>
          <div style={styles.statDivider} />
          <div style={styles.statItem}>
            <span style={styles.statNumber}>{followerCount}</span>
            <span style={styles.statLabel}>Followers</span>
          </div>
        </div>
      </div>

      {/* Categories Section */}
      <div style={styles.categoriesContainer}>
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            style={{
              ...styles.categoryButton,
              ...(selectedCategory === category &&
                styles.selectedCategoryButton),
            }}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Posts Grid */}
      <div style={styles.postsContainer}>
        {isLoading ? (
          <div style={styles.loadingContainer}>Loading...</div>
        ) : filteredPosts.length === 0 ? (
          <ProfileEmptyState
            category={selectedCategory}
            onCreatePress={() => navigate("/create")}
          />
        ) : (
          <>
            {filteredPosts.map((post) => (
              <VideoCard
                key={post.$id}
                title={post.title}
                creator={post.creator.username || user?.username || ""}
                avatar={post.creator?.avatar || user?.avatar || ""}
                media={post.media}
                category={post.category}
                prompt={post.prompt}
                currentUserId={user?.$id}
                creatorId={post.creator?.$id}
                postId={post.$id}
                cost={post.cost}
                budget={post.budget}
                currency={post.currency}
                onDeletePress={() => handleDeletePost(post.$id)}
                showDelete={true}
                isGloballyMuted={isGloballyMuted}
                onMuteChange={setIsGloballyMuted}
                updatePostDetails={updatePostDetails}
              />
            ))}
            <div ref={loadingTriggerRef} style={styles.loadingTrigger}>
              {hasMore && <div style={styles.loadingMore}>Loading more...</div>}
            </div>
          </>
        )}
      </div>

      {/* Menu Modal */}
      {isMenuOpen && (
        <div style={styles.menuModal}>
          <div style={styles.menuContent}>
            <button
              onClick={() => navigate("/edit-profile")}
              style={styles.menuItem}
            >
              Edit Profile
            </button>
            <button onClick={handleLogout} style={styles.menuItem}>
              Logout
            </button>
            <button
              onClick={() => setIsMenuOpen(false)}
              style={styles.menuCloseButton}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  // ... styles remain unchanged
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
  },
  header: {
    marginBottom: "24px",
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuButton: {
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
  },
  aiButton: {
    backgroundColor: "#6366F1",
    padding: "8px 16px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "white",
  },
  aiButtonIcon: {
    fontSize: "20px",
  },
  aiButtonText: {
    fontSize: "16px",
    fontWeight: "600",
  },
  profileSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "32px",
  },
  avatarContainer: {
    width: "120px",
    height: "120px",
    borderRadius: "60px",
    overflow: "hidden",
    border: "3px solid #f0f0f0",
    marginBottom: "16px",
  },
  avatar: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  username: {
    fontSize: "24px",
    fontWeight: "600",
    margin: "0 0 16px 0",
  },
  statsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    padding: "16px",
    backgroundColor: "#f8f9fa",
    borderRadius: "12px",
    width: "100%",
    maxWidth: "400px",
  },
  statItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  statNumber: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#1a1a1a",
  },
  statLabel: {
    fontSize: "14px",
    color: "#666",
    marginTop: "4px",
  },
  statDivider: {
    width: "1px",
    height: "24px",
    backgroundColor: "#e1e1e1",
  },
  categoriesContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "12px",
    marginBottom: "24px",
  },
  categoryButton: {
    padding: "8px 16px",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    fontSize: "14px",
    backgroundColor: "#f0f0f0",
    color: "#666",
  },
  selectedCategoryButton: {
    backgroundColor: "#000",
    color: "#fff",
  },
  postsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  loadingContainer: {
    textAlign: "center",
    padding: "40px",
    color: "#666",
  },
  loadingTrigger: {
    height: "20px",
    margin: "20px 0",
  },
  loadingMore: {
    textAlign: "center",
    color: "#666",
  },
  menuModal: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  menuContent: {
    backgroundColor: "white",
    padding: "24px",
    borderRadius: "12px",
    minWidth: "300px",
  },
  menuItem: {
    width: "100%",
    padding: "12px",
    marginBottom: "8px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    backgroundColor: "#f8f9fa",
  },
  menuCloseButton: {
    width: "100%",
    padding: "12px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    backgroundColor: "#000",
    color: "#fff",
    marginTop: "16px",
  },
};

export default Profile;
