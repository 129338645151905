import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import images from "../constants/images";
import ImageSlideshow from "./ImageSlideShow";
import { HashLink } from "react-router-hash-link";
import BurgerMenu from "../components/BurgerMenu";

// Import any components you use in your existing site
// import Navbar from '../components/Navbar';
// import Footer from '../components/Footer';

const BasketballCamp = () => {
  // Animation variants for consistent motion effects
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
  };

  const slideUp = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.6 } },
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="container mx-auto px-6 py-4 flex justify-end">
        <BurgerMenu />
      </div>

      {/* Hero Section */}
      <section className="relative h-[70vh] bg-gradient-to-r from-orange-500 to-red-600 flex items-center justify-center mb-12">
        <div className="absolute inset-0 bg-black opacity-30"></div>
        <div className="container mx-auto px-6 relative z-10 text-center">
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            className="text-5xl md:text-6xl font-bold text-white mb-6"
          >
            CREATOR CONNECT'S YOUTH BASKETBALL CAMP
          </motion.h1>
          <motion.p
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            className="text-xl md:text-2xl text-white mb-10 max-w-3xl mx-auto"
          >
            Providing young athletes with skills, leadership training, and
            competitive play in a structured and fun environment.
          </motion.p>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={slideUp}
            className="mb-6"
          >
            <Link
              to="/register"
              className="bg-white text-orange-600 font-bold py-4 px-10 rounded-full text-lg hover:bg-orange-100 transition duration-300 inline-block shadow-lg"
            >
              Register Now
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Camp Overview with Slideshow */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Camp Overview
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={slideUp}
              className="overflow-hidden rounded-lg shadow-xl"
            >
              {/* Replace the static image with the slideshow component */}
              <ImageSlideshow />
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={slideUp}
              className="space-y-6"
            >
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  Location
                </h3>
                <p className="text-gray-600">Detroit Country Day</p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  Dates & Duration
                </h3>
                <p className="text-gray-600">
                  August 4th to August 8th (Monday - Friday)
                </p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold text-gray-800 mb-2">Ages</h3>
                <p className="text-gray-600">
                  6-14 years old (Boys basketball)
                </p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  Daily Schedule
                </h3>
                <p className="text-gray-600">
                  9:00 AM - 3:00 PM with lunch break included
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Staff */}
      {/* Staff */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Experienced Staff
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: "Donnavan Kirk",
                description:
                  "Former professional basketball player with experience in multiple international leagues including Lithuania and Japan. Camp founder and head director who brings elite-level training techniques to youth development.",
                image: images.image64,
              },
              {
                title: "Aaron Winshall",
                description:
                  "Elite skills trainer who has worked with NBA talent and specializes in guard development. Known for innovative drills that rapidly improve ball handling and shooting mechanics.",
                image: images.image50,
              },
              {
                title: "Nick Friedman",
                description:
                  "NBA Coach and guest instructor who shares professional-level insights and training techniques. Focuses on defensive positioning and competitive mindset development.",
                image: images.image85,
              },
            ].map((person, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={slideUp}
              >
                <div className="bg-white rounded-lg shadow-md overflow-hidden h-full flex flex-col">
                  <div className="aspect-[4/3] bg-gray-300">
                    <img
                      src={person.image}
                      alt={person.title}
                      className="w-full h-full object-contain object-center"
                    />
                  </div>
                  <div className="p-6 flex-grow">
                    <h3 className="text-xl font-bold text-gray-800 mb-2">
                      {person.title}
                    </h3>
                    <p className="text-gray-600">{person.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Camp Objectives */}
      <section className="py-16">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Camp Objectives
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
          </motion.div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                title: "Skills",
                description: "Teach fundamental basketball skills.",
                image: images.image48,
              },
              {
                title: "Teamwork",
                description: "Develop teamwork and sportsmanship.",
                image: images.image32,
              },
              {
                title: "Mentorship",
                description: "Provide guidance from experienced players.",
                image: images.image37,
              },
              {
                title: "Fun",
                description: "Create a fun, engaging environment.",
                image: images.image69,
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={slideUp}
                className="bg-white p-8 rounded-lg shadow-md text-center"
              >
                <div className="aspect-square mb-4 rounded-lg overflow-hidden">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-full object-contain"
                  />
                </div>
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {item.title}
                </h3>
                <p className="text-gray-600">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Daily Schedule */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Daily Schedule
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={slideUp}
            className="max-w-4xl mx-auto bg-white rounded-xl shadow-md overflow-hidden"
          >
            <div className="p-8">
              {[
                { time: "9:00 - 9:30 AM", activity: "Warm-up & Stretching" },
                { time: "9:30 - 10:30 AM", activity: "Skill Development" },
                {
                  time: "10:30 - 11:30 AM",
                  activity: "Drills & Technique Training",
                },
                { time: "11:30 - 12:30 PM", activity: "Lunch Break" },
                {
                  time: "12:30 - 2:00 PM",
                  activity: "Team Drills & Scrimmages",
                },
                {
                  time: "2:00 - 3:00 PM",
                  activity: "Competitive Play & Leadership Talks",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className={`flex py-4 ${
                    index !== 5 ? "border-b border-gray-200" : ""
                  }`}
                >
                  <div className="w-1/3 pr-4 font-semibold text-gray-800">
                    {item.time}
                  </div>
                  <div className="w-2/3 text-gray-600">{item.activity}</div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>

      {/* Championship and Awards */}
      <section className="py-16">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Championship and Awards
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Championship Tournament",
                description:
                  "The camp concludes with a Championship Tournament on the last day.",
                icon: "🏆",
              },
              {
                title: "Awards",
                description:
                  "Awards are given for sportsmanship and improvement.",
                icon: "🥇",
              },
              {
                title: "Recognition",
                description: "The MVP will be recognized.",
                icon: "👑",
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={slideUp}
                className="bg-white p-8 rounded-lg shadow-md text-center"
              >
                <div className="text-5xl mb-4">{item.icon}</div>
                <h3 className="text-xl font-bold text-gray-800 mb-3">
                  {item.title}
                </h3>
                <p className="text-gray-600">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Facilities */}
      <section className="py-16">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Facilities and Equipment
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
          </motion.div>

          {/* Featured Gym Image */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={slideUp}
            className="mb-12 overflow-hidden rounded-xl shadow-lg"
          >
            <img
              src={images.gym}
              alt="Detroit Country Day Upper School Basketball Court"
              className="w-full h-auto md:h-96 object-cover"
            />
            <div className="bg-white p-6 text-center">
              <h3 className="text-2xl font-bold text-gray-800">
                Detroit Country Day Upper School
              </h3>
            </div>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Professional Court",
                description:
                  "Full-size basketball courts with high-quality hardwood flooring, professional-grade hoops, and proper court markings for authentic game experience.",
              },
              {
                title: "Complete Equipment",
                description:
                  "Access to premium training equipment including regulation basketballs, adjustable hoops, agility ladders, training cones, resistance bands, and shooting machines.",
              },
              {
                title: "Support Facilities",
                description:
                  "Climate-controlled environment, spacious locker rooms, hydration stations, first aid center, and dedicated rest areas for breaks between intensive training sessions.",
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={slideUp}
                className="bg-white p-8 rounded-lg shadow-md"
              >
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {item.title}
                </h3>
                <p className="text-gray-600">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Sponsorship Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Sponsor Our Camp
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
            <p className="mt-4 text-gray-600 max-w-3xl mx-auto">
              Support youth basketball development and gain valuable exposure
              for your brand.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Rookie Level",
                price: "$1,000",
                highlight: "3 Scholarships",
                features: "Website & Email Promotion",
                icon: "🏀",
              },
              {
                title: "All-Star Level",
                price: "$2,500",
                highlight: "7 Scholarships",
                features: "Jersey Placement & Welcome Packs",
                icon: "⭐",
              },
              {
                title: "MVP Level",
                price: "$5,000",
                highlight: "12 Scholarships",
                features: "Media Promotion & Speaking Opportunity",
                icon: "🏆",
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={slideUp}
                className="bg-white p-8 rounded-lg shadow-md text-center relative overflow-hidden"
              >
                {index === 1 && (
                  <div className="absolute top-0 right-0 bg-orange-500 text-white px-3 py-1 text-sm font-bold">
                    Popular
                  </div>
                )}
                <div className="text-5xl mb-4">{item.icon}</div>
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {item.title}
                </h3>
                <p className="text-3xl font-bold text-orange-600 my-4">
                  {item.price}
                </p>
                <p className="text-gray-800 font-semibold mb-1">
                  {item.highlight}
                </p>
                <p className="text-gray-600 text-sm mb-6">{item.features}</p>
                {/* <Link
                  to="/sponsorships"
                  className="inline-block bg-orange-500 text-white font-bold py-2 px-6 rounded-full hover:bg-orange-600 transition duration-300"
                >
                  Learn More
                </Link> */}
              </motion.div>
            ))}
          </div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mt-12"
          >
            <Link
              to="/sponsorships"
              className="bg-white border-2 border-orange-500 text-orange-600 font-bold py-3 px-8 rounded-full text-lg hover:bg-orange-50 transition duration-300 inline-block mx-2"
            >
              View All Packages
            </Link>
            <Link
              to="https://buy.stripe.com/bIYcPFaiw7455b29AA"
              className="bg-orange-500 text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-orange-600 transition duration-300 inline-block mx-2"
            >
              Make a Donation
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Registration CTA */}
      <section className="py-20 bg-gradient-to-r from-orange-500 to-red-600 text-white">
        <div className="container mx-auto px-6 text-center">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-4xl font-bold mb-6"
          >
            Ready to Join the Camp?
          </motion.h2>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-xl mb-8 max-w-2xl mx-auto"
          >
            Secure your spot now for Donnavan Kirk's Youth Basketball Camp.
            Limited spots available!
          </motion.p>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={slideUp}
          >
            <Link
              to="/register"
              className="bg-white text-orange-600 font-bold py-4 px-10 rounded-full text-lg hover:bg-orange-100 transition duration-300 inline-block"
            >
              Register Now
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-16">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Contact Us
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={slideUp}
            className="max-w-3xl mx-auto text-center"
          >
            <p className="text-xl text-gray-600 mb-8">
              Have questions? Reach out to us for more information about the
              camp.
            </p>
            <p className="text-lg text-gray-800 font-semibold mb-2">
              Contact: Donnavan Kirk
            </p>
            <p className="text-lg text-gray-800 mb-6">
              Email: basketball@creatorconect.com
            </p>
            <Link
              to="/contact"
              className="bg-orange-500 text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-orange-600 transition duration-300 inline-block"
            >
              Get In Touch
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Footer would go here */}
      {/* <Footer /> */}
    </div>
  );
};

export default BasketballCamp;
