// src/services/firebase/firebase.js
import {
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { getMessaging } from "firebase/messaging";
import { app, auth, db } from "./firebaseInit";

const storage = getStorage(app);
const messaging = getMessaging(app);

export { auth, db, storage, messaging };

export const uploadFile = async (file, folder) => {
  try {
    if (!file) throw new Error("No file provided");

    const timestamp = Date.now();
    const fileExtension = file.name.split(".").pop();
    const fileName = `${folder}_${timestamp}.${fileExtension}`;
    const fileReference = storageRef(storage, `${folder}/${fileName}`);

    await uploadBytes(fileReference, file);
    const url = await getDownloadURL(fileReference);
    return url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

// Improved video creation with additional fields
export const createVideo = async (data) => {
  try {
    if (!auth.currentUser) {
      throw new Error("User not authenticated");
    }

    let mediaUrl = null;
    if (data.media) {
      const mediaType = data.media.type.startsWith("video/")
        ? "videos"
        : "images";
      mediaUrl = await uploadFile(data.media, mediaType);
    }

    const postData = {
      title: data.title || "",
      prompt: data.prompt || "",
      category: data.category || "Portfolio",
      creator: auth.currentUser.uid,
      media: mediaUrl,
      mediaType: data.media?.type.startsWith("video/") ? "video" : "image",
      mediaDuration: data.media?.duration || null,
      mediaSize: data.media?.size || null,
      cost: data.cost ? parseFloat(data.cost) : null,
      budget: data.budget ? parseFloat(data.budget) : null,
      currency: data.currency || "USD",
      searchKeywords: data.searchKeywords || [],
      createdAt: serverTimestamp(),
    };

    const docRef = await addDoc(collection(db, "videos"), postData);
    return { $id: docRef.id, ...postData };
  } catch (error) {
    console.error("Error creating video:", error);
    throw error;
  }
};

// Improved posts fetching with error handling
export const getAllPosts = async (limit_count = 5) => {
  try {
    if (!db) throw new Error("Firestore not initialized");

    const postsQuery = query(
      collection(db, "videos"),
      orderBy("createdAt", "desc"),
      limit(limit_count)
    );

    const snapshot = await getDocs(postsQuery);
    return snapshot.docs.map((doc) => ({
      $id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate(), // Convert timestamp to Date
    }));
  } catch (error) {
    console.error("Error fetching posts:", error);
    throw error;
  }
};
