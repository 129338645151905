// src/components/video-card/MediaContent.web.jsx
import React, { memo, useState, useCallback } from "react";

export const MediaContent = memo(
  ({
    media,
    isImage,
    mediaDimensions,
    onImageLoad,
    onVideoLoad,
    videoRef,
    isViewable,
    category,
    cost,
    budget,
    currency,
    isGloballyMuted,
    onMuteChange,
  }) => {
    // console.log("MediaContent rendering with:", { media, isImage });

    const [isMuted, setIsMuted] = useState(isGloballyMuted);
    const [videoError, setVideoError] = useState(false);

    const handleMuteToggle = useCallback(() => {
      const newMuteState = !isMuted;
      setIsMuted(newMuteState);
      onMuteChange(newMuteState);
    }, [isMuted, onMuteChange]);

    const renderPriceOverlay = () => {
      const price = category === "Portfolio" ? cost : budget;
      if (!price) return null;

      return (
        <div style={styles.priceOverlay}>
          <span style={styles.priceOverlayText}>
            {currency} {parseFloat(price).toLocaleString()}
          </span>
        </div>
      );
    };

    return (
      <div style={styles.mediaWrapper}>
        {isImage ? (
          <img
            src={media}
            alt="Media content"
            style={{ ...styles.media, ...mediaDimensions }}
            onLoad={onImageLoad}
          />
        ) : (
          <div style={styles.videoWrapper}>
            <video
              ref={videoRef}
              src={media}
              style={{ ...styles.media, ...mediaDimensions }}
              controls
              muted={isMuted}
              autoPlay={isViewable}
              loop
              onLoadedData={onVideoLoad}
              onError={(e) => {
                console.error("Video loading error:", e);
                setVideoError(true);
              }}
            />
            {videoError && (
              <div style={styles.errorMessage}>
                Error loading video. Please try again later.
              </div>
            )}

            <button
              style={styles.muteButton}
              onClick={handleMuteToggle}
              aria-label="Toggle mute"
            >
              {isMuted ? "🔇" : "🔊"}
            </button>
          </div>
        )}
        {renderPriceOverlay()}
      </div>
    );
  }
);

const styles = {
  mediaWrapper: {
    position: "relative",
    width: "100%",
    borderRadius: "8px",
    overflow: "hidden",
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  videoWrapper: {
    position: "relative",
  },
  muteButton: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    border: "none",
    borderRadius: "50%",
    padding: "8px",
    cursor: "pointer",
  },
  priceOverlay: {
    position: "absolute",
    top: "10px",
    left: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    color: "#fff",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
  },
  priceOverlayText: {
    fontWeight: "bold",
  },
};
