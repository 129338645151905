// src/app/(auth)/sign-up.web.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUser } from "../lib/firebase.web";
import { useGlobalContext } from "../context/FirebaseGlobalProvider";

const SignUp = () => {
  const navigate = useNavigate();
  const { setUser } = useGlobalContext();
  const [form, setForm] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async (e) => {
    e.preventDefault();

    if (!form.username || !form.email || !form.password) {
      alert("Please fill in all the fields");
      return;
    }

    if (form.password.length < 6) {
      alert("Password must be at least 6 characters long");
      return;
    }

    setIsSubmitting(true);
    console.log("Starting signup process...");

    try {
      console.log("Creating user...");
      const result = await createUser(
        form.email,
        form.password,
        form.username,
        true
      );

      console.log("User created successfully:", { userId: result?.$id });

      if (result) {
        console.log("Setting user in global context...");
        setUser(result);
        navigate("/auth/email-verification"); // Fixed path
        console.log("Navigated to email verification");
      }
    } catch (error) {
      console.error("Signup error:", error);
      let errorMessage = "Failed to create account";

      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage = "This email is already registered";
          break;
        case "auth/invalid-email":
          errorMessage = "Invalid email address";
          break;
        case "auth/operation-not-allowed":
          errorMessage = "Email/password accounts are not enabled";
          break;
        case "auth/weak-password":
          errorMessage = "Password is too weak";
          break;
        default:
          errorMessage = error.message;
      }

      alert(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>SIGN UP</h1>
      <form onSubmit={submit} style={styles.form}>
        <div style={styles.formField}>
          <label style={styles.label}>Username</label>
          <input
            type="text"
            value={form.username}
            onChange={(e) => setForm({ ...form, username: e.target.value })}
            style={styles.input}
          />
        </div>

        <div style={styles.formField}>
          <label style={styles.label}>Email</label>
          <input
            type="email"
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
            style={styles.input}
            autoCapitalize="none"
          />
        </div>

        <div style={styles.formField}>
          <label style={styles.label}>Password</label>
          <input
            type="password"
            value={form.password}
            onChange={(e) => setForm({ ...form, password: e.target.value })}
            style={styles.input}
          />
        </div>

        <button type="submit" style={styles.button} disabled={isSubmitting}>
          {isSubmitting ? "Signing up..." : "Sign Up"}
        </button>
      </form>

      <div style={styles.footer}>
        <span style={styles.footerText}>Do you have an account already?</span>
        <button onClick={() => navigate("/sign-in")} style={styles.footerLink}>
          Sign In
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    maxWidth: "400px",
    margin: "0 auto",
    minHeight: "100vh",
    backgroundColor: "white",
  },
  header: {
    fontSize: "2rem",
    fontFamily: "Oswald-Medium, sans-serif",
    marginBottom: "1.5rem",
    color: "#333",
    textAlign: "left",
    width: "100%",
  },
  form: {
    width: "100%",
  },
  formField: {
    marginBottom: "1.25rem",
    width: "100%",
  },
  label: {
    display: "block",
    marginBottom: "0.5rem",
    fontSize: "1rem",
    color: "#333",
    fontFamily: "Roboto-Regular, sans-serif",
  },
  input: {
    width: "100%",
    padding: "0.75rem",
    fontSize: "1rem",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontFamily: "Roboto-Regular, sans-serif",
  },
  button: {
    width: "100%",
    padding: "0.75rem",
    fontSize: "1rem",
    backgroundColor: "#222528",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "1rem",
    fontFamily: "Roboto-Regular, sans-serif",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.5rem",
    gap: "0.5rem",
  },
  footerText: {
    fontSize: "1rem",
    color: "#666",
  },
  footerLink: {
    background: "none",
    border: "none",
    fontSize: "1rem",
    color: "#DC2D5A",
    fontWeight: "bold",
    cursor: "pointer",
    padding: 0,
  },
};

export default SignUp;
