// PaymentConfirmation.jsx
import React from "react";
import { useLocation, Link } from "react-router-dom";
import { motion } from "framer-motion";

const PaymentConfirmation = () => {
  const location = useLocation();
  const paymentInfo = location.state?.paymentInfo;

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
  };

  const getConfirmationMessage = () => {
    if (paymentInfo?.type === "sponsorship") {
      return {
        title: "Thank You for Your Sponsorship!",
        message: `Your ${paymentInfo.packageName} sponsorship package has been confirmed. You'll receive a confirmation email with details about next steps and how to provide your logo and branding materials.`,
      };
    } else if (paymentInfo?.type === "donation") {
      return {
        title: "Thank You for Your Donation!",
        message: `Your generous donation of $${paymentInfo.amount} will help provide scholarships for underprivileged youth to attend our basketball camp. You'll receive a confirmation email with a tax receipt shortly.`,
      };
    } else {
      return {
        title: "Thank You for Your Payment!",
        message:
          "Your payment has been processed successfully. You'll receive a confirmation email shortly.",
      };
    }
  };

  const confirmation = getConfirmationMessage();

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
          <div className="bg-green-500 px-6 py-8 text-center">
            <svg
              className="mx-auto h-16 w-16 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <h1 className="text-3xl font-bold text-white mt-4">
              {confirmation.title}
            </h1>
          </div>

          <div className="p-8">
            <motion.div initial="hidden" animate="visible" variants={fadeIn}>
              <div className="text-center mb-8">
                <p className="text-gray-600 mb-6">{confirmation.message}</p>

                <div className="bg-gray-100 p-4 rounded-lg text-left mb-8">
                  <h3 className="font-bold mb-2">Transaction Details</h3>
                  <p className="text-gray-700 mb-1">
                    <span className="font-semibold">Date:</span>{" "}
                    {new Date().toLocaleDateString()}
                  </p>
                  <p className="text-gray-700 mb-1">
                    <span className="font-semibold">Transaction ID:</span>{" "}
                    {paymentInfo?.transactionId ||
                      Math.random().toString(36).substring(2, 12).toUpperCase()}
                  </p>
                  <p className="text-gray-700">
                    <span className="font-semibold">Amount:</span> $
                    {paymentInfo?.amount || "0.00"}
                  </p>
                </div>
              </div>

              <div className="flex justify-center">
                <Link
                  to="/"
                  className="bg-orange-500 text-white font-bold py-3 px-8 rounded-lg hover:bg-orange-600 transition duration-300 mx-2"
                >
                  Return Home
                </Link>
                <Link
                  to="/sponsorships"
                  className="bg-gray-300 text-gray-800 font-bold py-3 px-8 rounded-lg hover:bg-gray-400 transition duration-300 mx-2"
                >
                  Back to Sponsorships
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
