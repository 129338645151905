import React from "react";

const FeatureShowcase = () => {
  const features = [
    {
      title: "Showcase Your Work",
      description:
        "Share your latest projects, services, and unique vision through curated posts. Whether you're an artist, designer, filmmaker, or musician, bring your creations to life.",
      gif: "https://firebasestorage.googleapis.com/v0/b/ccfirebasetest.firebasestorage.app/o/videos%2Fezgif.com-video-to-gif-converter.gif?alt=media&token=56929fec-a34f-467a-85ad-776c60646dfa",
      color: "bg-indigo-50",
    },
    {
      title: "Interactive Creative Map",
      description:
        "Discover and share creativity worldwide. Tag your work's location and explore a global gallery of art, innovation, and inspiration.",
      gif: "https://firebasestorage.googleapis.com/v0/b/ccfirebasetest.firebasestorage.app/o/videos%2Fezgif.com-video-to-gif-converter-2.gif?alt=media&token=27a30a1e-b5e8-45e4-9ba5-cf6665a8a684",
      color: "bg-rose-50",
    },
    {
      title: "Smart Collaboration",
      description:
        "Connect with like-minded creators, send proposals, and find opportunities. Our platform makes creative collaboration seamless and secure.",
      gif: "https://firebasestorage.googleapis.com/v0/b/ccfirebasetest.firebasestorage.app/o/videos%2Fezgif.com-video-to-gif-converter-3.gif?alt=media&token=6c5abcf8-b4f5-469f-b468-2c3c1cd8969e",
      color: "bg-amber-50",
    },
    {
      title: "Portfolio Collections",
      description:
        "Organize your physical and digital assets in beautiful collections. Make it easy for others to explore and engage with your work.",
      gif: "https://firebasestorage.googleapis.com/v0/b/ccfirebasetest.firebasestorage.app/o/videos%2Fezgif.com-video-to-gif-converter-4.gif?alt=media&token=dd7a6038-946c-4fa8-a025-60fd3d871c39",
      color: "bg-emerald-50",
    },
  ];

  return (
    <div className="py-16 bg-white overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Where Creativity Meets Opportunity
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            Creator Connect is your platform to showcase, collaborate, and grow
            in the creative community.
          </p>
        </div>

        {/* Features Grid - Vertical on mobile, horizontal scroll on larger screens */}
        <div className="relative">
          <div className="flex flex-col md:flex-row md:overflow-x-auto md:snap-x md:snap-mandatory gap-8 pb-4 -mx-4 px-4 md:px-0 hide-scrollbar">
            {features.map((feature, index) => (
              <div
                key={index}
                className={`flex-none w-full md:w-[600px] lg:w-[800px] rounded-2xl ${feature.color} p-6 md:p-8 snap-center transform transition-transform duration-300 hover:scale-[1.02]`}
              >
                <div className="flex flex-col h-full">
                  <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-600 mb-6">{feature.description}</p>

                  {/* Modified GIF container for vertical videos */}
                  <div className="relative rounded-lg overflow-hidden bg-gray-100 mt-auto h-[500px]">
                    {feature.gif ? (
                      <img
                        src={feature.gif}
                        alt={feature.title}
                        className="absolute inset-0 w-full h-full object-contain"
                      />
                    ) : (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <p className="text-gray-400">GIF placeholder</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Scroll indicators - Only visible on larger screens */}
          <div className="hidden md:block absolute -bottom-6 left-0 right-0">
            <div className="flex justify-center gap-2">
              {features.map((_, index) => (
                <div key={index} className="w-2 h-2 rounded-full bg-gray-300" />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Custom styles for hiding scrollbar while maintaining functionality */}
      <style jsx>{`
        .hide-scrollbar {
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default FeatureShowcase;
