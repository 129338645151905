import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { resendVerificationEmail, signOut } from "../lib/firebase.web";
import { useGlobalContext } from "../context/FirebaseGlobalProvider";

const EmailVerification = () => {
  const navigate = useNavigate();
  const { user, setUser, setIsLoggedIn, refreshVerificationStatus } =
    useGlobalContext();
  const [checking, setChecking] = useState(false);
  const [resending, setResending] = useState(false);
  const [loading, setLoading] = useState(true); // To show loading initially

  useEffect(() => {
    const verifyEmailStatus = async () => {
      try {
        console.log("Initial email verification check...");
        const isVerified = await refreshVerificationStatus();
        if (isVerified) {
          console.log("Email is verified, navigating to tabs...");
          setUser((prevUser) => ({ ...prevUser, emailVerified: true }));
          setIsLoggedIn(true);
          navigate("/tabs");
        } else {
          console.log("Email not verified, waiting for user...");
        }
      } catch (error) {
        console.error("Error during verification check:", error);
      } finally {
        setLoading(false); // Ensure loading is turned off
      }
    };

    verifyEmailStatus();

    // Poll for verification every 5 seconds
    const intervalId = setInterval(verifyEmailStatus, 5000);

    return () => clearInterval(intervalId);
  }, [refreshVerificationStatus, setUser, setIsLoggedIn, navigate]);

  const handleResendEmail = async () => {
    try {
      setResending(true);
      await resendVerificationEmail();
      alert("Verification email has been resent.");
    } catch (error) {
      console.error("Error resending verification email:", error);
      alert("Failed to resend verification email. Please try again.");
    } finally {
      setResending(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null);
      setIsLoggedIn(false);
      navigate("/auth/sign-in");
    } catch (error) {
      console.error("Error signing out:", error);
      alert("Failed to sign out. Please try again.");
    }
  };

  if (loading) {
    return <div style={styles.loading}>Waiting for email verification...</div>;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Verify Your Email</h1>

      <div style={styles.content}>
        <p style={styles.description}>A verification email has been sent to:</p>
        <p style={styles.email}>{user?.email || "your email address"}</p>

        <p style={styles.instructions}>
          Please check your email and click the verification link to continue.
          Don't forget to check your spam folder.
        </p>

        <button
          onClick={handleResendEmail}
          disabled={resending}
          style={{
            ...styles.button,
            backgroundColor: resending ? "#ddd" : "#007bff",
            cursor: resending ? "not-allowed" : "pointer",
          }}
        >
          {resending ? "Sending..." : "Resend Verification Email"}
        </button>

        <button
          onClick={handleSignOut}
          style={{
            ...styles.button,
            backgroundColor: "#f44336",
          }}
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    minHeight: "100vh",
    backgroundColor: "#f9f9f9",
  },
  header: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "1rem",
  },
  content: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  description: {
    fontSize: "1rem",
    color: "#555",
    marginBottom: "0.5rem",
  },
  email: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#000",
    marginBottom: "1.5rem",
  },
  instructions: {
    fontSize: "1rem",
    color: "#666",
    marginBottom: "1rem",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    marginBottom: "10px",
    width: "100%",
    maxWidth: "300px",
  },
  loading: {
    fontSize: "1.5rem",
    textAlign: "center",
    marginTop: "50px",
  },
};

export default EmailVerification;
