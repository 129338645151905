// src/lib/firebase.web.js
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification as sendVerification,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../services/firebase/firebaseInit";

export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  } catch (error) {
    console.error("Sign in error:", error);
    throw error;
  }
};

export const getCurrentUser = async () => {
  const user = auth.currentUser;
  if (!user) return null;

  const userDoc = await getDoc(doc(db, "users", user.uid));
  return {
    $id: user.uid,
    ...userDoc.data(),
    email: user.email,
    emailVerified: user.emailVerified,
  };
};

export const createUser = async (email, password, username, agreedToTerms) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    const userData = {
      email,
      username,
      agreedToTerms,
      emailVerified: false,
      avatar: `https://ui-avatars.com/api/?name=${encodeURIComponent(
        username
      )}`,
      createdAt: serverTimestamp(),
    };

    await setDoc(doc(db, "users", user.uid), userData);
    await sendVerification(user);

    return { $id: user.uid, ...userData };
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const checkEmailVerification = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("No user logged in");

    await user.reload();
    return user.emailVerified;
  } catch (error) {
    console.error("Error checking email verification:", error);
    throw error;
  }
};

export const resendVerificationEmail = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("No user logged in");

    await sendVerification(user);
    return true;
  } catch (error) {
    console.error("Error resending verification email:", error);
    throw error;
  }
};

export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
    return true;
  } catch (error) {
    console.error("Error signing out:", error);
    throw error;
  }
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

// Updated function to save registration data to Firestore with payment status
export const saveRegistrationData = async (
  formData,
  payment_status = "pending"
) => {
  try {
    // Save the formData to a new document in a 'registrations' collection
    const docRef = await addDoc(collection(db, "registrations"), {
      ...formData,
      payment_status: payment_status, // Add payment status
      registrationDate: serverTimestamp(), // Add a timestamp
    });

    const registrationId = docRef.id;

    console.log(
      "Registration data saved successfully with ID:",
      registrationId
    );
    return registrationId; // Return the registration ID
  } catch (error) {
    console.error("Error saving registration data:", error);
    throw error;
  }
};

// Add this function to firebase.web.js
export const sendRegistrationEmail = async (
  formData,
  registrationId,
  payment_status
) => {
  try {
    // Only send confirmation email if payment was successful
    if (payment_status !== "paid") {
      console.log("Payment not successful, skipping confirmation email");
      return;
    }

    // Extract player names for the email
    const playersList = formData.players
      .map((player) => `${player.playerFirstName} ${player.playerLastName}`)
      .join(", ");

    // Create HTML email content
    const emailHtml = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
        <h1 style="color: #FF6600; margin-bottom: 30px; text-align: center;">Basketball Camp Registration Confirmation</h1>

        <p style="font-size: 16px; color: #333; margin-bottom: 20px;">
          Dear ${formData.parentFirstName} ${formData.parentLastName},
        </p>

        <p style="font-size: 16px; color: #333; margin-bottom: 20px;">
          Thank you for registering for Donnavan Kirk's Youth Basketball Camp! Your registration has been received and your payment was successful.
        </p>

        <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; margin-bottom: 30px;">
          <p style="font-size: 16px; color: #444; line-height: 1.6;">
            <strong>Registration ID:</strong> ${registrationId}<br>
            <strong>Player(s):</strong> ${playersList}<br>
          </p>
        </div>

        <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; margin-bottom: 30px;">
          <h3 style="color: #333; margin-top: 0;">Camp Details:</h3>
          <ul style="font-size: 16px; color: #444; line-height: 1.6;">
            <li><strong>Location:</strong> Detroit Country Day</li>
            <li><strong>Dates:</strong> August 4th to August 8th (Monday - Friday)</li>
            <li><strong>Time:</strong> 9:00 AM - 3:00 PM</li>
            <li><strong>What to bring:</strong> Basketball shoes, water bottle, and a great attitude</li>
          </ul>
        </div>

        <p style="font-size: 16px; color: #333; margin-bottom: 20px;">
          If you have any questions or need to make changes to your registration, please contact us at camp@yourdomain.com.
        </p>
      </div>
    `;

    // Create a document in the emails collection to trigger email sending
    await addDoc(collection(db, "registrations"), {
      to: formData.email,
      from: "noreply@creatorconnect.com", // Use your SendGrid verified sender
      message: {
        subject: "Basketball Camp Registration Confirmation",
        html: emailHtml,
      },
      // Metadata
      registrationId: registrationId,
      parentName: `${formData.parentFirstName} ${formData.parentLastName}`,
      createdAt: serverTimestamp(),
      emailType: "registration_confirmation",
      players: formData.players.map((player) => ({
        name: `${player.playerFirstName} ${player.playerLastName}`,
        age: player.age,
      })),
    });

    console.log("Registration confirmation email queued successfully!");
    return true;
  } catch (error) {
    console.error("Error sending registration confirmation email:", error);
    return false;
  }
};
// In your firebase.web.js file, add this function:
export const saveTransactionData = async (transactionData) => {
  try {
    // Change "firestore" to "db" here
    const transactionRef = await addDoc(collection(db, "transactions"), {
      ...transactionData,
      timestamp: serverTimestamp(),
    });

    return transactionRef.id;
  } catch (error) {
    console.error("Error saving transaction data:", error);
    throw error;
  }
};

// Function to send sponsorship confirmation email
export const sendSponsorshipEmail = async (transactionData, transactionId) => {
  try {
    // Only send confirmation email if payment was successful
    if (transactionData.status !== "paid") {
      console.log("Payment not successful, skipping confirmation email");
      return false;
    }

    // Get appropriate content based on sponsorship type or donation
    const getEmailContent = () => {
      if (transactionData.type === "donation") {
        return {
          subject: "Thank You for Your Donation to Our Basketball Camp",
          greeting: `Thank you for your generous donation of $${transactionData.amount}.00 to our youth basketball camp scholarship fund.`,
          details: `Your contribution will help provide opportunities for underprivileged youth to participate in our basketball camp. Each $1 donation provides one full scholarship for a child.`,
        };
      } else {
        // For sponsorships
        return {
          subject: `${transactionData.packageName} Sponsorship Confirmation - Basketball Camp`,
          greeting: `Thank you for becoming a ${transactionData.packageName} sponsor for our youth basketball camp.`,
          details: `Your sponsorship package includes funding for ${transactionData.scholarships} youth scholarships and various promotional benefits for your organization as outlined in your selected package.`,
        };
      }
    };

    const content = getEmailContent();
    const customerName = transactionData.customerInfo.name;
    const companyInfo = transactionData.customerInfo.company
      ? `<p style="font-size: 16px; color: #333; margin-bottom: 20px;">Company: ${transactionData.customerInfo.company}</p>`
      : "";

    // Create HTML email content
    const emailHtml = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
        <h1 style="color: #FF6600; margin-bottom: 30px; text-align: center;">Basketball Camp Sponsorship Confirmation</h1>

        <p style="font-size: 16px; color: #333; margin-bottom: 20px;">
          Dear ${customerName},
        </p>
        
        ${companyInfo}

        <p style="font-size: 16px; color: #333; margin-bottom: 20px;">
          ${content.greeting}
        </p>

        <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; margin-bottom: 30px;">
          <p style="font-size: 16px; color: #444; line-height: 1.6;">
            <strong>Transaction ID:</strong> ${transactionId}<br>
            <strong>Amount:</strong> $${transactionData.amount}.00<br>
            <strong>Date:</strong> ${new Date().toLocaleDateString()}<br>
          </p>
        </div>

        <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; margin-bottom: 30px;">
          <h3 style="color: #333; margin-top: 0;">Details:</h3>
          <p style="font-size: 16px; color: #444; line-height: 1.6;">
            ${content.details}
          </p>
        </div>

        <p style="font-size: 16px; color: #333; margin-bottom: 20px;">
          If you have any questions about your ${
            transactionData.type
          }, please contact us at basketball@creatorconnect.com.
        </p>
        
        <p style="font-size: 16px; color: #333; margin-bottom: 20px;">
          Thank you for supporting youth basketball in our community!
        </p>
      </div>
    `;

    // Create a document in the emails collection to trigger email sending
    await addDoc(collection(db, "registrations"), {
      to: transactionData.customerInfo.email,
      from: "noreply@creatorconnect.com", // Use your SendGrid verified sender
      message: {
        subject: content.subject,
        html: emailHtml,
      },
      // Metadata
      transactionId: transactionId,
      customerName: customerName,
      createdAt: serverTimestamp(),
      emailType:
        transactionData.type === "donation"
          ? "donation_confirmation"
          : "sponsorship_confirmation",
      amount: transactionData.amount,
      packageName:
        transactionData.type === "sponsorship"
          ? transactionData.packageName
          : "Donation",
    });

    console.log("Sponsorship/donation confirmation email queued successfully!");
    return true;
  } catch (error) {
    console.error("Error sending sponsorship confirmation email:", error);
    return false;
  }
};

// Add this function to firebase.web.js
export const saveDonationData = async (amount, type, metadata) => {
  try {
    const donationData = {
      amount: amount,
      type: type,
      metadata: metadata,
      status: "pending_payment",
      created_at: serverTimestamp(),
    };

    const docRef = await addDoc(collection(db, "donations"), donationData);
    const donationId = docRef.id;

    console.log("Donation data saved successfully with ID:", donationId);
    return donationId; // Return the donation ID
  } catch (error) {
    console.error("Error saving donation data:", error);
    throw error;
  }
};

export { auth, db };
