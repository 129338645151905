// src/components/HomeEmptyState.web.jsx
import React from "react";

const HomeEmptyState = ({ onSearchPress }) => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.iconContainer}>
          <span style={styles.iconText}>🎨</span>
        </div>

        <h1 style={styles.title}>Start Your Creative Journey</h1>

        <p style={styles.subtitle}>
          Your feed is waiting to be filled with inspiration. Start by following
          other creators to see their work here.
        </p>

        <div style={styles.instructionsCard}>
          <h2 style={styles.instructionsTitle}>Here's how to get started:</h2>

          <div style={styles.stepContainer}>
            <div style={styles.stepNumber}>1</div>
            <p style={styles.stepText}>
              Use the search tab to discover creators in your field
            </p>
          </div>

          <div style={styles.stepContainer}>
            <div style={styles.stepNumber}>2</div>
            <p style={styles.stepText}>
              Visit their profiles and follow those whose work inspires you
            </p>
          </div>

          <div style={styles.stepContainer}>
            <div style={styles.stepNumber}>3</div>
            <p style={styles.stepText}>
              Share your own work to start building your creative portfolio
            </p>
          </div>
        </div>

        <button onClick={onSearchPress} style={styles.button}>
          Discover Creators
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#fff",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  content: {
    maxWidth: "600px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconContainer: {
    width: "80px",
    height: "80px",
    borderRadius: "40px",
    backgroundColor: "#EEF2FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "24px",
  },
  iconText: {
    fontSize: "32px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#1a1a1a",
    textAlign: "center",
    marginBottom: "12px",
    fontFamily: "Oswald-Medium, sans-serif",
  },
  subtitle: {
    fontSize: "16px",
    color: "#666666",
    textAlign: "center",
    marginBottom: "32px",
    lineHeight: "24px",
    fontFamily: "Roboto-Regular, sans-serif",
  },
  instructionsCard: {
    backgroundColor: "#F9FAFB",
    borderRadius: "12px",
    padding: "20px",
    width: "100%",
    marginBottom: "32px",
  },
  instructionsTitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#1a1a1a",
    marginBottom: "16px",
    fontFamily: "Oswald-Medium, sans-serif",
  },
  stepContainer: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "16px",
    gap: "12px",
  },
  stepNumber: {
    width: "24px",
    height: "24px",
    borderRadius: "12px",
    backgroundColor: "#6366F1",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "600",
  },
  stepText: {
    flex: 1,
    fontSize: "16px",
    color: "#666666",
    lineHeight: "24px",
    margin: 0,
    fontFamily: "Roboto-Regular, sans-serif",
  },
  button: {
    backgroundColor: "#6366F1",
    borderRadius: "12px",
    padding: "16px 24px",
    width: "100%",
    border: "none",
    color: "white",
    fontSize: "18px",
    fontWeight: "600",
    fontFamily: "Oswald-Medium, sans-serif",
    cursor: "pointer",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "translateY(-2px)",
    },
  },
};

export default HomeEmptyState;
