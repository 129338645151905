// src/context/StripeContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";

const StripeContext = createContext(null);

export const StripeProvider = ({ children }) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripeInstance = await loadStripe(
          "pk_live_51PDgmKRotvaadvKDcnKp3zgkkbtvnLlIT0z0xgbFACtNUusf0IT3wfnnw8VQH5fsclUHh5hdlwFHg6zN0ZKrOBcg00jR7L7JtF"
        );
        if (stripeInstance) {
          setStripe(stripeInstance);
        } else {
          console.error("Stripe failed to initialize.");
        }
      } catch (error) {
        console.error("Error initializing Stripe:", error);
      }
    };

    initializeStripe();
  }, []);

  return (
    <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>
  );
};

export const useStripeContext = () => {
  return useContext(StripeContext);
};
