// constants/slidesData.js
import images from "../constants/images";

const slidesData = [
    {
        image: images.image85,
        alt: "Nick Friedman traiing Lamelo Ball, Star NBA Player",
        caption: "Nick Friedman training Lamelo Ball, Star NBA Player"
      },
  {
    image: images.image1,
    alt: "Donnavan Kirk and teammate and NBA Player Davon Reed",
    caption: "Donnavan Kirk and teammate and NBA Player Davon Reed"
  },
  {
    image: images.image50,
    alt: "Aaron Winshall coaching OKC NBA Star guard",
    caption: "Aaron Winshall coaching OKC Star guard"
  },
  
  {
    image: images.image84,
    alt: "Donnavan Kirk University of Miami Basketball Player",
    caption: "Donnavan Kirk at The University of Miami"
  },
  {
    image: images.image83,
    alt: "Donnavan Kirk class of 2009 Detroit Country Day",
    caption: "Donnavan Kirk class of 2009 Detroit Country Day"
  },
  {
    image: images.image82,
    alt: "Donnavan Kirk Dunking against University of Virginia",
    caption: "Donnavan Kirk Dunking against University of Virginia"
  },
  {
    image: images.image81,
    alt: "Donnavan Kirk dunking against top Shot Blocker in the country",
    caption: "Donnavan Kirk dunking against top Shot Blocker in the country"
  },
  {
    image: images.image80,
    alt: "Donnavan Kirk visit Steve Harvey Show",
    caption: "Donnavan Kirk visit Steve Harvey Show"
  },
  {
    image: images.image79,
    alt: "Donnavan Kirk and Lebron James",
    caption: "Donnavan Kirk and Lebron James"
  },
  {
    image: images.image78,
    alt: "Donnavan Kirk in Lithuania",
    caption: "Donnavan Kirk in Lithuania with Sailui"
  },
  {
    image: images.image77,
    alt: "Donnavan Kirk in Fukuoka Japan",
    caption: "Donnavan Kirk in Fukuoka Japan"
  },
  {
    image: images.image74,
    alt: "Donnavan Kirk dunking on someone in Japan",
    caption: "Donnavan Kirk dunking on someone in Japan"
  },
  // Additional slides using your other images
  {
    image: images.image3,
    alt: "Basketball training session",
    caption: "Professional training techniques at our basketball camp"
  },
  {
    image: images.image4,
    alt: "Youth basketball development",
    caption: "Developing young talent with proven methods"
  },
  {
    image: images.image10,
    alt: "Basketball camp drills",
    caption: "Specialized drills to improve ball handling skills"
  },
  {
    image: images.image15,
    alt: "Team building exercises",
    caption: "Building teamwork and camaraderie among young athletes"
  },
//   {
//     image: images.image20,
//     alt: "Shooting form training",
//     caption: "Perfect your shooting technique with expert coaching"
//   },
//   {
//     image: images.image25,
//     alt: "Defensive strategy session",
//     caption: "Learning the fundamentals of strong defensive play"
//   },
//   {
//     image: images.image30,
//     alt: "Basketball camp scrimmage",
//     caption: "Putting skills into practice with supervised gameplay"
//   },
  {
    image: images.image40,
    alt: "One-on-one coaching",
    caption: "Personalized attention from experienced coaches"
  },
 
];

export default slidesData;