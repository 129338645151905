import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useGlobalContext } from "../context/FirebaseGlobalProvider";

// Import tab pages
import Home from "./home.web";
import Search from "./search.web";
import Create from "./create.web";
import Notification from "./notification.web";
import Profile from "./profile.web";

// Tab icons
import HomeIcon from "../assets/icons/home.png";
import SearchIcon from "../assets/icons/search.png";
import PlusIcon from "../assets/icons/plus.png";
import NotificationIcon from "../assets/icons/notifications.png";
import ProfileIcon from "../assets/icons/profile.png";

// TabIcon component
const TabIcon = ({ icon, isActive }) => (
  <img
    src={icon}
    alt="tab icon"
    style={{
      width: 24,
      height: 24,
      filter: isActive ? "none" : "grayscale(100%)",
    }}
  />
);

const TabsLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useGlobalContext();

  const handleMediaUpload = async () => {
    try {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*, video/*";

      input.onchange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        // Validate file type and size
        const isVideo = file.type.startsWith("video/");
        const isImage = file.type.startsWith("image/");

        if (!isVideo && !isImage) {
          alert("Please select an image or video file");
          return;
        }

        if (isVideo && file.size > 50 * 1024 * 1024) {
          // 50MB limit for videos
          alert("Video file size must be less than 50MB");
          return;
        }

        if (isImage && file.size > 5 * 1024 * 1024) {
          // 5MB limit for images
          alert("Image file size must be less than 5MB");
          return;
        }

        // Create media object
        const media = {
          uri: URL.createObjectURL(file),
          type: file.type,
          fileName: file.name,
          fileSize: file.size,
        };

        // Navigate to create page with media
        navigate("/tabs/create", { state: { media } });
      };

      input.click();
    } catch (error) {
      console.error("Media upload error:", error);
      alert("Failed to process media. Please try again.");
    }
  };

  const tabs = [
    { path: "/tabs/home", icon: HomeIcon, label: "Home" },
    { path: "/tabs/search", icon: SearchIcon, label: "Search" },
    {
      path: "/tabs/create",
      icon: PlusIcon,
      label: "Create",
      onClick: handleMediaUpload,
    },
    {
      path: "/tabs/notification",
      icon: NotificationIcon,
      label: "Notifications",
    },
    { path: "/tabs/profile", icon: ProfileIcon, label: "Profile" },
  ];

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/create" element={<Create />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>

      <nav style={styles.tabBar}>
        {tabs.map((tab) => (
          <button
            key={tab.path}
            onClick={() => (tab.onClick ? tab.onClick() : navigate(tab.path))}
            style={styles.tabButton}
          >
            <TabIcon
              icon={tab.icon}
              isActive={location.pathname === tab.path}
            />
            <span
              style={{
                ...styles.tabLabel,
                color: location.pathname === tab.path ? "#000" : "#C2C6CB",
              }}
            >
              {tab.label}
            </span>
          </button>
        ))}
      </nav>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#fff",
  },
  content: {
    flex: 1,
    overflow: "auto",
  },
  tabBar: {
    display: "flex",
    justifyContent: "space-evenly", // Adjusted for better spacing
    alignItems: "center",
    height: "84px",
    borderTop: "1px solid #232533",
    backgroundColor: "#fff",
    padding: "0 10px", // Add some padding for better spacing on small screens
  },
  tabButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    padding: "12px",
    border: "none",
    background: "none",
    cursor: "pointer",
  },
  tabLabel: {
    fontSize: "12px",
    transition: "color 0.2s",
  },
};

// Media Queries for Responsive Design
const mediaQueries = {
  "@media (max-width: 768px)": {
    tabBar: {
      flexDirection: "row", // Stack tabs horizontally on smaller screens
      justifyContent: "space-between", // Space out the tabs on small screens
      height: "70px", // Adjust tab height
    },
    tabLabel: {
      fontSize: "10px", // Reduce font size on smaller screens
    },
    tabButton: {
      padding: "8px", // Reduce padding for smaller screens
    },
  },
  "@media (max-width: 480px)": {
    tabLabel: {
      fontSize: "9px", // Even smaller font size for very small screens
    },
    tabButton: {
      padding: "6px", // Further reduce padding for very small screens
    },
  },
};

export default TabsLayout;
