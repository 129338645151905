// Registration.jsx - Main registration page
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { saveRegistrationData } from "../lib/firebase.web";

const Registration = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    // Parent/Guardian information
    parentFirstName: "",
    parentLastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",

    // Players information (array of player objects)
    players: [
      {
        playerFirstName: "",
        playerLastName: "",
        dateOfBirth: "",
        age: "",
        shirtSize: "",
        emergencyContact: "",
        emergencyPhone: "",
        medicalConditions: "",
      },
    ],

    // Waiver
    waiverAgreed: false,
    mediaReleaseAgreed: false,
  });

  // Load data from localStorage on component mount
  useEffect(() => {
    try {
      const storedData = localStorage.getItem("registrationFormData");
      if (storedData) {
        setFormData(JSON.parse(storedData));
      }
      const storedStep = localStorage.getItem("registrationStep");
      if (storedStep) {
        setStep(parseInt(storedStep, 10));
      }
    } catch (error) {
      console.error("Error loading data from localStorage:", error);
    }
  }, []);

  // Save data to localStorage whenever formData changes
  useEffect(() => {
    try {
      localStorage.setItem("registrationFormData", JSON.stringify(formData));
      localStorage.setItem("registrationStep", step.toString());
    } catch (error) {
      console.error("Error saving data to localStorage:", error);
    }
  }, [formData, step]);

  const handleInputChange = (e, playerIndex = null) => {
    const { name, value, type, checked } = e.target;

    if (playerIndex !== null) {
      // Handle player fields
      setFormData((prevData) => {
        const updatedPlayers = [...prevData.players];
        updatedPlayers[playerIndex] = {
          ...updatedPlayers[playerIndex],
          [name]: type === "checkbox" ? checked : value,
        };
        return {
          ...prevData,
          players: updatedPlayers,
        };
      });
    } else {
      // Handle other fields
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const addPlayer = () => {
    setFormData((prevData) => ({
      ...prevData,
      players: [
        ...prevData.players,
        {
          playerFirstName: "",
          playerLastName: "",
          dateOfBirth: "",
          age: "",
          shirtSize: "",
          emergencyContact: "",
          emergencyPhone: "",
          medicalConditions: "",
        },
      ],
    }));
  };

  const removePlayer = (index) => {
    if (formData.players.length === 1) {
      // Don't remove if it's the last player
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      players: prevData.players.filter((_, i) => i !== index),
    }));
  };

  const nextStep = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  const prevStep = () => {
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  // Registration.jsx

  const submitRegistration = async () => {
    try {
      // Save registration data to Firestore with pending status
      const registrationId = await saveRegistrationData(formData, "pending");

      // Navigate to payment page, passing both formData and registrationId
      navigate("/payment", { state: { formData, registrationId } });
    } catch (error) {
      console.error("Error saving registration data:", error);
      // Handle the error appropriately (e.g., display an error message to the user)
      alert(
        "An error occurred while saving your registration. Please try again."
      ); // Simple error handling
    }
  };

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
          <div className="bg-gradient-to-r from-orange-500 to-red-600 px-6 py-4">
            <Link
              to="/basketballcamp"
              className="text-white flex items-center mb-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back to Camp Information
            </Link>
            <h1 className="text-3xl font-bold text-white">Camp Registration</h1>
          </div>

          {/* Progress Indicator */}
          <div className="px-6 py-4 bg-gray-100 border-b">
            <div className="flex justify-between items-center">
              <div
                className={`flex items-center ${
                  step >= 1 ? "text-orange-500" : "text-gray-400"
                }`}
              >
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center mr-2 ${
                    step >= 1
                      ? "bg-orange-500 text-white"
                      : "bg-gray-200 text-gray-500"
                  }`}
                >
                  1
                </div>
                <span className="hidden sm:inline">Parent Info</span>
              </div>
              <div
                className={`flex-1 h-1 mx-2 ${
                  step >= 2 ? "bg-orange-500" : "bg-gray-200"
                }`}
              ></div>
              <div
                className={`flex items-center ${
                  step >= 2 ? "text-orange-500" : "text-gray-400"
                }`}
              >
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center mr-2 ${
                    step >= 2
                      ? "bg-orange-500 text-white"
                      : "bg-gray-200 text-gray-500"
                  }`}
                >
                  2
                </div>
                <span className="hidden sm:inline">Player Info</span>
              </div>
              <div
                className={`flex-1 h-1 mx-2 ${
                  step >= 3 ? "bg-orange-500" : "bg-gray-200"
                }`}
              ></div>
              <div
                className={`flex items-center ${
                  step >= 3 ? "text-orange-500" : "text-gray-400"
                }`}
              >
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center mr-2 ${
                    step >= 3
                      ? "bg-orange-500 text-white"
                      : "bg-gray-200 text-gray-500"
                  }`}
                >
                  3
                </div>
                <span className="hidden sm:inline">Waiver</span>
              </div>
              <div
                className={`flex-1 h-1 mx-2 ${
                  step >= 4 ? "bg-orange-500" : "bg-gray-200"
                }`}
              ></div>
              <div
                className={`flex items-center ${
                  step >= 4 ? "text-orange-500" : "text-gray-400"
                }`}
              >
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center mr-2 ${
                    step >= 4
                      ? "bg-orange-500 text-white"
                      : "bg-gray-200 text-gray-500"
                  }`}
                >
                  4
                </div>
                <span className="hidden sm:inline">Review</span>
              </div>
            </div>
          </div>

          <div className="p-8">
            {/* Step 1: Parent/Guardian Information */}
            // Step 1: Parent/Guardian Information (corrected version)
            {step === 1 && (
              <motion.div initial="hidden" animate="visible" variants={fadeIn}>
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                  Parent/Guardian Information
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="parentFirstName"
                    >
                      First Name*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="parentFirstName"
                      name="parentFirstName"
                      type="text"
                      value={formData.parentFirstName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="parentLastName"
                    >
                      Last Name*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="parentLastName"
                      name="parentLastName"
                      type="text"
                      value={formData.parentLastName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="email"
                    >
                      Email*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="phone"
                    >
                      Phone Number*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="phone"
                      name="phone"
                      type="tel"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="address"
                  >
                    Address*
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="address"
                    name="address"
                    type="text"
                    value={formData.address}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="city"
                    >
                      City*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="city"
                      name="city"
                      type="text"
                      value={formData.city}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="state"
                    >
                      State*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="state"
                      name="state"
                      type="text"
                      value={formData.state}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="zip"
                    >
                      ZIP Code*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="zip"
                      name="zip"
                      type="text"
                      value={formData.zip}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={nextStep}
                  >
                    Next
                  </button>
                </div>
              </motion.div>
            )}
            {step === 2 && (
              <motion.div initial="hidden" animate="visible" variants={fadeIn}>
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-800">
                    Player Information
                  </h2>
                  <button
                    type="button"
                    onClick={addPlayer}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Add Player
                  </button>
                </div>

                {formData.players.map((player, index) => (
                  <div
                    key={index}
                    className="mb-8 p-5 border border-gray-200 rounded-lg bg-white shadow-sm"
                  >
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg font-bold text-gray-700">
                        Player #{index + 1}
                      </h3>
                      {formData.players.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removePlayer(index)}
                          className="text-red-500 hover:text-red-700 font-medium"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor={`playerFirstName-${index}`}
                        >
                          First Name*
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id={`playerFirstName-${index}`}
                          name="playerFirstName"
                          type="text"
                          value={player.playerFirstName}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </div>
                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor={`playerLastName-${index}`}
                        >
                          Last Name*
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id={`playerLastName-${index}`}
                          name="playerLastName"
                          type="text"
                          value={player.playerLastName}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </div>
                    </div>
                    {/* The rest of the player form fields with index-based IDs and onChange handlers */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor={`dateOfBirth-${index}`}
                        >
                          Date of Birth*
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id={`dateOfBirth-${index}`}
                          name="dateOfBirth"
                          type="date"
                          value={player.dateOfBirth}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </div>
                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor={`age-${index}`}
                        >
                          Age*
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id={`age-${index}`}
                          name="age"
                          type="number"
                          min="6"
                          max="14"
                          value={player.age}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor={`shirtSize-${index}`}
                      >
                        T-Shirt Size*
                      </label>
                      <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id={`shirtSize-${index}`}
                        name="shirtSize"
                        value={player.shirtSize}
                        onChange={(e) => handleInputChange(e, index)}
                        required
                      >
                        <option value="">Select Size</option>
                        <option value="YS">Youth Small</option>
                        <option value="YM">Youth Medium</option>
                        <option value="YL">Youth Large</option>
                        <option value="AS">Adult Small</option>
                        <option value="AM">Adult Medium</option>
                        <option value="AL">Adult Large</option>
                        <option value="AXL">Adult XL</option>
                      </select>
                    </div>
                    <div className="mb-8">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor={`medicalConditions-${index}`}
                      >
                        Medical Conditions/Allergies
                      </label>
                      <textarea
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id={`medicalConditions-${index}`}
                        name="medicalConditions"
                        rows="3"
                        value={player.medicalConditions}
                        onChange={(e) => handleInputChange(e, index)}
                      ></textarea>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor={`emergencyContact-${index}`}
                        >
                          Emergency Contact Name*
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id={`emergencyContact-${index}`}
                          name="emergencyContact"
                          type="text"
                          value={player.emergencyContact}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </div>
                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor={`emergencyPhone-${index}`}
                        >
                          Emergency Contact Phone*
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id={`emergencyPhone-${index}`}
                          name="emergencyPhone"
                          type="tel"
                          value={player.emergencyPhone}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </div>
                    </div>{" "}
                  </div>
                ))}

                <div className="flex justify-between mt-6">
                  <button
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={prevStep}
                  >
                    Back
                  </button>
                  <button
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={nextStep}
                  >
                    Next
                  </button>
                </div>
              </motion.div>
            )}
            {/* Step 3: Waiver */}
            {step === 3 && (
              <motion.div initial="hidden" animate="visible" variants={fadeIn}>
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                  Waivers and Agreements
                </h2>

                <div className="mb-8 p-4 bg-gray-100 rounded-lg">
                  <h3 className="text-lg font-bold mb-3">
                    Liability Waiver and Medical Release
                  </h3>
                  <p className="text-sm leading-relaxed mb-4">
                    I understand that basketball involves certain inherent risks
                    and that my child may be injured as a result of their
                    participation. I hereby give permission for the camp staff
                    to secure emergency medical services for my child if needed.
                    I acknowledge that I will be responsible for any medical or
                    other charges incurred. I release Donnavan Kirk, his staff,
                    Detroit Country Day, and all other associated entities from
                    any claims arising out of injuries my child may sustain
                    while participating in the camp activities.
                  </p>
                  <div className="flex items-start mb-4">
                    <input
                      className="mt-1 mr-2"
                      id="waiverAgreed"
                      name="waiverAgreed"
                      type="checkbox"
                      checked={formData.waiverAgreed}
                      onChange={handleInputChange}
                      required
                    />
                    <label
                      className="text-sm font-bold text-gray-700"
                      htmlFor="waiverAgreed"
                    >
                      I agree to the liability waiver and medical release*
                    </label>
                  </div>
                </div>

                <div className="mb-8 p-4 bg-gray-100 rounded-lg">
                  <h3 className="text-lg font-bold mb-3">Media Release</h3>
                  <p className="text-sm leading-relaxed mb-4">
                    I grant permission for my child to be photographed or
                    recorded during camp activities and understand that these
                    images may be used in promotional materials, social media,
                    and/or the camp website.
                  </p>
                  <div className="flex items-start">
                    <input
                      className="mt-1 mr-2"
                      id="mediaReleaseAgreed"
                      name="mediaReleaseAgreed"
                      type="checkbox"
                      checked={formData.mediaReleaseAgreed}
                      onChange={handleInputChange}
                    />
                    <label
                      className="text-sm font-bold text-gray-700"
                      htmlFor="mediaReleaseAgreed"
                    >
                      I agree to the media release (optional)
                    </label>
                  </div>
                </div>

                <div className="flex justify-between">
                  <button
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={prevStep}
                  >
                    Back
                  </button>
                  <button
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={nextStep}
                    disabled={!formData.waiverAgreed}
                  >
                    Next
                  </button>
                </div>
              </motion.div>
            )}
            {step === 4 && (
              <motion.div initial="hidden" animate="visible" variants={fadeIn}>
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                  Review and Submit
                </h2>

                <div className="mb-8">
                  <h3 className="text-lg font-bold mb-3">Camp Details</h3>
                  <div className="bg-gray-100 p-4 rounded-lg">
                    <p>
                      <strong>Location:</strong> Detroit Country Day
                    </p>
                    <p>
                      <strong>Dates:</strong> A week in July (Monday - Friday)
                    </p>
                    <p>
                      <strong>Time:</strong> 9:00 AM - 3:00 PM
                    </p>
                    <p>
                      <strong>Price:</strong> $525 per camper
                    </p>
                  </div>
                </div>

                <div className="mb-8">
                  <h3 className="text-lg font-bold mb-3">
                    Registration Summary
                  </h3>

                  <div className="bg-gray-100 p-4 rounded-lg mb-4">
                    <h4 className="font-bold mb-2">
                      Parent/Guardian Information
                    </h4>
                    <p>
                      <strong>Name:</strong> {formData.parentFirstName}{" "}
                      {formData.parentLastName}
                    </p>
                    <p>
                      <strong>Email:</strong> {formData.email}
                    </p>
                    <p>
                      <strong>Phone:</strong> {formData.phone}
                    </p>
                    <p>
                      <strong>Address:</strong> {formData.address},{" "}
                      {formData.city}, {formData.state} {formData.zip}
                    </p>
                  </div>

                  {/* Player Information - Updated to properly display each player's details */}
                  {formData.players.map((player, index) => (
                    <div
                      key={index}
                      className="bg-gray-100 p-4 rounded-lg mb-4"
                    >
                      <h4 className="font-bold mb-2">
                        Player #{index + 1}: {player.playerFirstName}{" "}
                        {player.playerLastName}
                      </h4>
                      <p>
                        <strong>Date of Birth:</strong> {player.dateOfBirth}
                      </p>
                      <p>
                        <strong>Age:</strong> {player.age}
                      </p>
                      <p>
                        <strong>T-Shirt Size:</strong> {player.shirtSize}
                      </p>
                      <p>
                        <strong>Emergency Contact:</strong>{" "}
                        {player.emergencyContact} ({player.emergencyPhone})
                      </p>
                      {player.medicalConditions && (
                        <p>
                          <strong>Medical Conditions/Allergies:</strong>{" "}
                          {player.medicalConditions}
                        </p>
                      )}
                    </div>
                  ))}

                  {/* Payment summary */}
                  <div className="bg-gray-100 p-4 rounded-lg mb-4">
                    <h4 className="font-bold mb-2">Payment Summary</h4>
                    <p>
                      <strong>Number of Players:</strong>{" "}
                      {formData.players.length}
                    </p>
                    <p>
                      <strong>Cost per Player:</strong> $525
                    </p>
                    <p className="text-lg font-bold text-orange-600">
                      <strong>Total Cost:</strong> $
                      {formData.players.length * 525}
                    </p>
                  </div>

                  <div className="bg-gray-100 p-4 rounded-lg">
                    <h4 className="font-bold mb-2">Agreements</h4>
                    <p>
                      <strong>Liability Waiver:</strong>{" "}
                      {formData.waiverAgreed ? "Agreed" : "Not Agreed"}
                    </p>
                    <p>
                      <strong>Media Release:</strong>{" "}
                      {formData.mediaReleaseAgreed ? "Agreed" : "Not Agreed"}
                    </p>
                  </div>
                </div>

                <div className="flex justify-between">
                  <button
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={prevStep}
                  >
                    Back
                  </button>
                  <button
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={submitRegistration}
                  >
                    Proceed to Payment
                  </button>
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
