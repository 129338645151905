import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Star, Download, MessageSquare, Users, Zap } from "lucide-react";
import images from "../constants/images";
import EmailSignupSection from "./EmailSignUpSection";
import FeatureShowcase from "./FeatureShowcase";
import RecentPosts from "./RecentPosts";
import RecentAnalytics from "./RecentAnalytics";
import BlogSection from "./BlogSection";
import BurgerMenu from "../components/BurgerMenu";

const LandingPage = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const videoUrl =
    "https://firebasestorage.googleapis.com/v0/b/ccfirebasetest.firebasestorage.app/o/videos%2FScreenRecording_03-17-2025%2011-35-21_1.mov?alt=media&token=72b5b5aa-cdc3-4ce1-95d7-1f49c5a1b5ff";

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const handleVideoError = (error) => {
    console.error("Video loading error:", error);
    setIsVideoLoaded(false);
  };

  return (
    <div className="w-full min-h-screen bg-white">
      {/* Hero Section */}
      <div className="bg-white text-black w-full">
        <div className="container mx-auto px-4 py-4 flex justify-end">
          <BurgerMenu />
        </div>
        <div className="container mx-auto px-4 py-8 md:py-16 lg:py-24">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-16">
            <div className="w-full lg:w-1/2 space-y-6">
              <h1 className="text-5xl md:text-4xl lg:text-6xl font-bold">
                CONNECT WITH CREATORS AT A PRESS OF A BUTTON{" "}
              </h1>
              <p className="text-lg md:text-xl text-gray-600">
                The all-in-one platform for creators to manage, grow, and
                monetize their physical and digital assets.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <a
                  href="https://itunes.apple.com/us/app/creator-connect/id1419740204?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transition-transform hover:scale-105"
                >
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/ccfirebasetest.firebasestorage.app/o/videos%2FDownload_on_the_App_Store_Badge.svg.png?alt=media&token=6f0b0522-7ce4-4a02-9a16-5ca1bbec23e5"
                    alt="Download on App Store"
                    className="h-12" // Adjust height as needed
                  />
                </a>
                {/* <Link
                  to="/auth/sign-in"
                  className="bg-white text-gray-900 px-8 py-3 rounded-lg font-semibold border border-gray-200 hover:bg-gray-50"
                >
                  Sign In
                </Link>
                <Link
                  to="/auth/sign-up"
                  className="bg-white text-gray-900 px-8 py-3 rounded-lg font-semibold border border-gray-200 hover:bg-gray-50"
                >
                  Sign Up
                </Link> */}
                {/* <Link
                  to="/basketball"
                  className="bg-white text-gray-900 px-8 py-3 rounded-lg font-semibold border border-gray-200 hover:bg-gray-50"
                >
                  Basketball Camp
                </Link> */}
              </div>
              <div className="mt-8 flex items-center gap-4">
                <div className="flex -space-x-2">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/ccfirebasetest.firebasestorage.app/o/avatars%2Favatar_0NLgXBRiutUvhVJ9bF1NfcIR5m92_1738700258724.jpg?alt=media&token=a2a5316d-d88a-4ded-959d-ba722966ce87"
                    alt="User"
                    className="w-8 h-8 rounded-full border-2 border-white"
                  />
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/ccfirebasetest.firebasestorage.app/o/avatars%2Favatar_1rJGrJMmDXVAPwvElsnotHqrEOR2_1734138390984.jpg?alt=media&token=1b7f55a5-696e-4ded-be4f-25b79f5d7cf3"
                    alt="User"
                    className="w-8 h-8 rounded-full border-2 border-white"
                  />
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/ccfirebasetest.firebasestorage.app/o/avatars%2F3ibBh92E3ZhJHYSbz5028mS5ixv1_1739804108118.jpg?alt=media&token=91c07e32-5fef-4d64-85c1-e46ed85f8408"
                    alt="User"
                    className="w-8 h-8 rounded-full border-2 border-white"
                  />
                </div>
                {/* <p className="text-sm text-gray-300">
                  Join 10,000+ creators already using our platform
                </p> */}
              </div>
            </div>
            <div className="w-full lg:w-1/2 relative">
              {/* Decorative background elements */}
              <div className="absolute -inset-4 bg-gradient-to-r from-pink-100 via-purple-100 to-indigo-100 rounded-xl blur-xl opacity-70"></div>
              <div className="absolute -inset-4 bg-gradient-to-br from-blue-100 to-purple-100 rounded-xl blur-lg opacity-40"></div>

              {/* Video container */}
              <div className="relative w-full rounded-xl shadow-2xl overflow-hidden bg-gradient-to-br from-white to-gray-50 p-2">
                <video
                  className="w-full h-full object-cover rounded-lg"
                  autoPlay
                  muted
                  loop
                  playsInline
                  onLoadedData={handleVideoLoad}
                  onError={handleVideoError}
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Influencer Analytics Section */}
      <RecentAnalytics />

      {/* Featured Creator Content Section */}
      <RecentPosts />

      {/* Featured Creator Content Section */}
      <FeatureShowcase />

      {/* Email Signup Section */}
      <EmailSignupSection />

      {/* Email Signup Section */}
      <BlogSection />

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <h3 className="font-bold text-lg mb-4">Creator Connect</h3>
              <p className="text-gray-400">
                Empowering creators to reach their full potential
              </p>
            </div>
            {/* <div>
              <h4 className="font-bold mb-4">Resources</h4>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="text-gray-400 hover:text-white">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-400 hover:text-white">
                    Help Center
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-400 hover:text-white">
                    Guides
                  </a>
                </li>
              </ul>
            </div> */}
            <div>
              <h4 className="font-bold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/privacy-policy"
                    className="text-gray-400 hover:text-white"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/termsofservices"
                    className="text-gray-400 hover:text-white"
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    to="/intellectual-property"
                    className="text-gray-400 hover:text-white"
                  >
                    Intellectual Property
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-bold mb-4">Connect</h4>
              <div className="flex space-x-4">
                <a
                  href="https://www.instagram.com/creator.connect/?hl=en"
                  className="text-gray-400 hover:text-white"
                >
                  Instagram
                </a>
              </div>
              <a
                href="https://www.youtube.com/@creatorconnect"
                className="text-gray-400 hover:text-white"
              >
                Youtube
              </a>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>&copy; 2025 Creator Connect. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg h-full">
    <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center text-blue-600 mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const TestimonialCard = ({ quote, author, role, rating }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg h-full">
    <div className="flex text-yellow-400 mb-4">
      {[...Array(rating)].map((_, i) => (
        <Star key={i} className="w-5 h-5 fill-current" />
      ))}
    </div>
    <p className="text-gray-700 mb-4">{quote}</p>
    <div>
      <p className="font-semibold">{author}</p>
      <p className="text-gray-600 text-sm">{role}</p>
    </div>
  </div>
);

export default LandingPage;
