import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import { MessageSquare, Users, Zap } from "lucide-react";

const RecentAnalytics = () => {
  const [recentSearches, setRecentSearches] = useState([]);
  const [loading, setLoading] = useState(true);
  // Add this utility function at the top of your component
  const formatNumber = (num) => {
    if (!num) return "0";

    const absNum = Math.abs(num);
    if (absNum >= 1e9) {
      return (num / 1e9).toFixed(1) + "B";
    } else if (absNum >= 1e6) {
      return (num / 1e6).toFixed(1) + "M";
    } else if (absNum >= 1e3) {
      return (num / 1e3).toFixed(1) + "K";
    }
    return num.toString();
  };

  useEffect(() => {
    const fetchRecentSearches = async () => {
      const db = getFirestore();
      const searches = [];

      // Fetch 3 most recent Instagram searches
      const instagramQuery = query(
        collection(db, "instagram_users"),
        orderBy("last_searched", "desc"),
        limit(2)
      );

      // Fetch most recent YouTube search
      const youtubeQuery = query(
        collection(db, "youtube_users"),
        orderBy("last_searched", "desc"),
        limit(1)
      );

      try {
        const [instagramDocs, youtubeDocs] = await Promise.all([
          getDocs(instagramQuery),
          getDocs(youtubeQuery),
        ]);

        instagramDocs.forEach((doc) => {
          searches.push({
            ...doc.data(),
            username: doc.id,
            platform: "instagram",
          });
        });

        youtubeDocs.forEach((doc) => {
          searches.push({
            ...doc.data(),
            username: doc.id,
            platform: "youtube",
          });
        });

        // Sort all searches by last_searched timestamp
        const sortedSearches = searches.sort(
          (a, b) => b.last_searched.toDate() - a.last_searched.toDate()
        );

        setRecentSearches(sortedSearches);
      } catch (error) {
        console.error("Error fetching recent searches:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentSearches();
  }, []);

  const calculateEngagementRate = (profile) => {
    if (profile.platform === "youtube") {
      return parseFloat(profile.engagement_rate || 0).toFixed(2);
    } else {
      if (!profile.media?.data) return 0;
      const recentPosts = profile.media.data.slice(0, 7);
      const totalLikes = recentPosts.reduce(
        (sum, post) => sum + (post.like_count || 0),
        0
      );
      const totalComments = recentPosts.reduce(
        (sum, post) => sum + (post.comments_count || 0),
        0
      );
      return (
        ((totalLikes + totalComments) /
          (recentPosts.length * profile.followers_count)) *
        100
      ).toFixed(2);
    }
  };

  const getAnalyticsSummary = (profile) => {
    if (profile.platform === "youtube") {
      const averageViews =
        profile.average_views ||
        (profile.view_count && profile.video_count
          ? Math.round(profile.view_count / profile.video_count)
          : 0);
      return `Average ${formatNumber(
        averageViews
      )} views per video with ${formatNumber(
        profile.video_count || 0
      )} total videos.`;
    } else {
      const engagementRate = calculateEngagementRate(profile);
      const avgEngagement = Math.round(
        profile.followers_count * (parseFloat(engagementRate) / 100)
      );
      return `Average engagement of ${engagementRate}% with approximately ${formatNumber(
        avgEngagement
      )} interactions per post.`;
    }
  };

  const getTimeSinceSearch = (timestamp) => {
    if (!timestamp) return "";
    const searchDate = timestamp.toDate();
    const now = new Date();
    const diffInMinutes = Math.floor((now - searchDate) / (1000 * 60));

    if (diffInMinutes < 60) {
      return `${diffInMinutes}m ago`;
    } else if (diffInMinutes < 1440) {
      return `${Math.floor(diffInMinutes / 60)}h ago`;
    } else {
      return `${Math.floor(diffInMinutes / 1440)}d ago`;
    }
  };

  return (
    <div className="py-16 bg-white w-full">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Recent Influencer Analytics
          </h2>
          <p className="text-gray-600 text-lg">
            Latest profiles analyzed across platforms
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {loading ? (
            <div className="col-span-full text-center text-gray-600">
              Loading recent analytics...
            </div>
          ) : (
            recentSearches.map((profile) => (
              <div
                key={profile.username}
                className="bg-white rounded-xl shadow-xl p-6 hover:shadow-2xl transition-shadow duration-300"
              >
                <div className="flex items-center gap-4 mb-6">
                  <div className="w-16 h-16 rounded-full overflow-hidden">
                    <img
                      src={profile.profile_picture_url}
                      alt={profile.username}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <h3 className="text-xl font-semibold">
                        @{profile.username}
                      </h3>
                      {/* <span className="text-xs text-gray-500">
                        {getTimeSinceSearch(profile.last_searched)}
                      </span> */}
                    </div>
                    <p className="text-gray-600">
                      {profile.platform === "youtube"
                        ? "YouTube Creator"
                        : "Instagram Creator"}
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-2 sm:gap-4 mb-6">
                  <div className="bg-gray-50 p-2 sm:p-4 rounded-lg text-center">
                    <p className="text-lg sm:text-xl lg:text-2xl font-bold text-blue-600 truncate">
                      {formatNumber(profile.followers_count || 0)}
                    </p>
                    <p className="text-xs sm:text-sm text-gray-600">
                      Followers
                    </p>
                  </div>
                  <div className="bg-gray-50 p-2 sm:p-4 rounded-lg text-center">
                    <p className="text-lg sm:text-xl lg:text-2xl font-bold text-blue-600 truncate">
                      {calculateEngagementRate(profile)}%
                    </p>
                    <p className="text-xs sm:text-sm text-gray-600">
                      Engagement
                    </p>
                  </div>
                  <div className="bg-gray-50 p-2 sm:p-4 rounded-lg text-center">
                    <p className="text-lg sm:text-xl lg:text-2xl font-bold text-blue-600 truncate">
                      {formatNumber(
                        profile.media_count || profile.video_count || 0
                      )}
                    </p>
                    <p className="text-xs sm:text-sm text-gray-600">
                      {profile.platform === "youtube" ? "Videos" : "Posts"}
                    </p>
                  </div>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2 text-sm sm:text-base">
                    Analytics Summary
                  </h4>
                  <p className="text-gray-600 text-xs sm:text-sm break-words">
                    {getAnalyticsSummary(profile)}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentAnalytics;
