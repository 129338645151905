// ProtectedRoute.jsx
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useGlobalContext } from "./context/FirebaseGlobalProvider";

export const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, isLoading, needsEmailVerification } = useGlobalContext();
  const location = useLocation();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-white">
        <div className="loading-spinner" />
        <p>Loading... Please wait.</p>
      </div>
    );
  }

  if (!isLoggedIn) {
    // Save the attempted URL
    return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;
  }

  if (needsEmailVerification) {
    return <Navigate to="/auth/email-verification" replace />;
  }

  return children;
};
