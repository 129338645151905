// src/services/firebase/firebaseConfig.js
const firebaseConfig = {
  apiKey: "AIzaSyAS19yxvfaCj6YM5vAcHgHHToNYjK9pnyM",
  authDomain: "ccfirebasetest.firebaseapp.com",
  projectId: "ccfirebasetest",
  storageBucket: "ccfirebasetest.firebasestorage.app",
  messagingSenderId: "197734236465",
  appId: "1:197734236465:web:a2925f0a8933a6c6417010",
  measurementId: "G-4K3QX0352S",
};

export default firebaseConfig;
