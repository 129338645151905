// src/components/SearchInput.web.jsx
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const SearchInput = ({ initialQuery = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState(initialQuery);

  const handleSearch = () => {
    if (!query.trim()) {
      alert("Please input something to search results across the database");
      return;
    }

    const trimmedQuery = query.trim();

    if (location.pathname.includes("/search/")) {
      // If we're already on the search screen, update the query param
      navigate(`/search/${trimmedQuery}`);
    } else {
      // Navigate to the search results page
      navigate(`/search/${trimmedQuery}`);
    }
  };

  return (
    <div style={styles.container}>
      <input
        type="text"
        value={query}
        placeholder="Search for Creators, Ideas and Moods"
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && handleSearch()}
        style={styles.input}
      />
      <button onClick={handleSearch} style={styles.button}>
        🔍
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "50px",
    padding: "0 16px",
    border: "2px solid black",
    borderRadius: "20px",
    backgroundColor: "transparent",
  },
  input: {
    flex: 1,
    fontSize: "16px",
    border: "none",
    outline: "none",
    fontFamily: "Oswald-Medium, sans-serif",
    color: "black",
  },
  button: {
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "18px",
  },
};

export default SearchInput;
