import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  getDoc,
  doc, // Make sure this is imported
} from "firebase/firestore";

const RecentPosts = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        const db = getFirestore();
        const postsQuery = query(
          collection(db, "videos"),
          orderBy("createdAt", "desc"),
          limit(3)
        );

        const snapshot = await getDocs(postsQuery);
        const posts = await Promise.all(
          snapshot.docs.map(async (document) => {
            // Changed 'doc' to 'document' to avoid naming conflict
            const postData = document.data();
            // Using the imported 'doc' function correctly
            const creatorDocRef = doc(db, "users", postData.creator);
            const creatorDoc = await getDoc(creatorDocRef);
            const creatorData = creatorDoc.exists() ? creatorDoc.data() : {};

            return {
              id: document.id,
              ...postData,
              creator: {
                id: postData.creator,
                ...creatorData,
              },
            };
          })
        );

        setRecentPosts(posts);
      } catch (error) {
        console.error("Error fetching recent posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentPosts();
  }, []);

  return (
    <div className="py-16 bg-gray-50 w-full">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Featured Creators
          </h2>
          <p className="text-gray-600 text-lg">
            Discover trending posts from our creative community
          </p>
        </div>

        <div className="grid lg:grid-cols-3 gap-8">
          {loading
            ? // Loading skeletons
              [...Array(3)].map((_, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg overflow-hidden animate-pulse"
                >
                  <div className="aspect-square bg-gray-200"></div>
                  <div className="p-4">
                    <div className="flex items-center gap-3 mb-3">
                      <div className="w-10 h-10 rounded-full bg-gray-200"></div>
                      <div className="space-y-2">
                        <div className="h-4 w-24 bg-gray-200 rounded"></div>
                        <div className="h-3 w-20 bg-gray-200 rounded"></div>
                      </div>
                    </div>
                    <div className="h-4 w-3/4 bg-gray-200 rounded mb-2"></div>
                    <div className="h-3 w-1/2 bg-gray-200 rounded"></div>
                  </div>
                </div>
              ))
            : recentPosts.map((post) => (
                <div
                  key={post.id}
                  className="bg-white rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="relative">
                    <img
                      src={post.media}
                      alt={post.title}
                      className="w-full aspect-square object-cover"
                    />
                    {post.location && (
                      <div className="absolute bottom-4 left-4">
                        {/* <button className="bg-white/90 hover:bg-white p-2 rounded-lg shadow-lg transition-all">
                          <img
                            src="/api/placeholder/24/24"
                            alt="Location"
                            className="w-6 h-6"
                          />
                        </button> */}
                      </div>
                    )}
                  </div>
                  <div className="p-4">
                    <div className="flex items-center gap-3 mb-3">
                      <img
                        src={post.creator?.avatar || "/api/placeholder/40/40"}
                        alt={post.creator?.username || "Creator"}
                        className="w-10 h-10 rounded-full"
                      />
                      <div>
                        <h3 className="font-semibold">
                          @{post.creator?.username || "creator"}
                        </h3>
                        <p className="text-sm text-gray-600">{post.category}</p>
                      </div>
                    </div>
                    <p className="text-gray-800 font-medium">{post.title}</p>
                    {post.budget && (
                      <p className="text-sm text-gray-600 mt-1">
                        Budget: {post.currency} {post.budget}
                      </p>
                    )}
                    {post.placeName && (
                      <p className="text-sm text-gray-600 mt-1">
                        Location: {post.placeName}
                      </p>
                    )}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default RecentPosts;
