import React, { useState, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/FirebaseGlobalProvider";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const Create = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const [form, setForm] = useState({
    title: "",
    media: location.state?.media || null,
    prompt: "",
    category: "Portfolio",
    cost: "",
    budget: "",
    currency: "USD",
  });

  const currencies = ["USD", "EUR", "GBP", "JPY"];

  // Handle file upload to Firebase Storage
  const uploadMedia = async (file) => {
    const storage = getStorage();
    const fileExtension = file.name.split(".").pop();
    const filename = `posts/${Date.now()}.${fileExtension}`;
    const storageRef = ref(storage, filename);

    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  // Handle file drop
  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileSelect(file);
    }
  }, []);

  // Handle drag events
  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  // Handle file selection
  const handleFileSelect = useCallback((file) => {
    if (!file) return;

    const isVideo = file.type.startsWith("video/");
    const isImage = file.type.startsWith("image/");

    if (!isVideo && !isImage) {
      alert("Please select an image or video file");
      return;
    }

    if (isVideo && file.size > 50 * 1024 * 1024) {
      alert("Video file size must be less than 50MB");
      return;
    }

    if (isImage && file.size > 5 * 1024 * 1024) {
      alert("Image file size must be less than 5MB");
      return;
    }

    setForm((prev) => ({
      ...prev,
      media: {
        uri: URL.createObjectURL(file),
        type: file.type,
        fileName: file.name,
        fileSize: file.size,
        file: file,
      },
    }));
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user?.uid && !user?.$id) {
      alert("User is not logged in. Please log in again.");
      return;
    }

    if (!form.media) {
      alert("Please upload media to create a post.");
      return;
    }

    if (!form.title.trim()) {
      alert("Please add a title for your post.");
      return;
    }

    setIsLoading(true);

    try {
      const mediaUrl = await uploadMedia(form.media.file);

      const searchKeywords = [
        form.title.trim().toLowerCase(),
        form.prompt.trim().toLowerCase(),
        form.category.toLowerCase(),
        form.cost ? `cost ${form.cost}` : null,
        form.budget ? `budget ${form.budget}` : null,
        form.currency ? form.currency.toLowerCase() : null,
      ].filter(Boolean);

      const data = {
        title: form.title.trim(),
        prompt: form.prompt.trim(),
        media: mediaUrl,
        category: form.category,
        cost: form.cost ? parseFloat(form.cost) : null,
        budget: form.budget ? parseFloat(form.budget) : null,
        currency: form.currency,
        userId: user.uid || user.$id,
        searchKeywords,
        createdAt: serverTimestamp(),
      };

      const db = getFirestore();
      await addDoc(collection(db, "videos"), data);

      alert("Post uploaded successfully");
      navigate("/tabs/home");
    } catch (error) {
      console.error("Error in submit:", error);
      alert(error.message || "Failed to create post. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <button onClick={() => navigate(-1)} style={styles.exitButton}>
          ← Back
        </button>
        <button
          onClick={handleSubmit}
          disabled={isLoading || !form.media}
          style={{
            ...styles.submitButton,
            ...(isLoading || !form.media ? styles.submitButtonDisabled : {}),
          }}
        >
          {isLoading ? "Uploading..." : "Submit"}
        </button>
      </div>

      <h1 style={styles.title}>Create Post</h1>

      <div
        style={{
          ...styles.dropZone,
          ...(dragActive ? styles.dropZoneActive : {}),
          ...(form.media ? styles.dropZoneWithMedia : {}),
        }}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={() => inputRef.current?.click()}
      >
        {form.media ? (
          form.media.type.startsWith("video/") ? (
            <video src={form.media.uri} style={styles.mediaPreview} controls />
          ) : (
            <img
              src={form.media.uri}
              alt="Upload preview"
              style={styles.mediaPreview}
            />
          )
        ) : (
          <div style={styles.dropZoneContent}>
            <p style={styles.dropZoneText}>
              Drag and drop your media here or click to select
            </p>
            <p style={styles.dropZoneSubtext}>
              Supports images and videos up to 50MB
            </p>
          </div>
        )}
        <input
          ref={inputRef}
          type="file"
          accept="image/*,video/*"
          onChange={(e) => handleFileSelect(e.target.files[0])}
          style={styles.hiddenInput}
        />
      </div>

      {/* Rest of your form components */}
      {/* Category selection */}
      <div style={styles.formSection}>
        <h2 style={styles.sectionTitle}>Category</h2>
        <div style={styles.categoryButtons}>
          {["Portfolio", "Ideas", "Moodboard"].map((category) => (
            <button
              key={category}
              onClick={() => setForm((prev) => ({ ...prev, category }))}
              style={{
                ...styles.categoryButton,
                ...(form.category === category
                  ? styles.categoryButtonActive
                  : {}),
              }}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      {/* Form fields */}
      <div style={styles.formSection}>
        <input
          type="text"
          value={form.title}
          onChange={(e) =>
            setForm((prev) => ({ ...prev, title: e.target.value }))
          }
          placeholder="Title"
          style={styles.input}
        />

        <textarea
          value={form.prompt}
          onChange={(e) =>
            setForm((prev) => ({ ...prev, prompt: e.target.value }))
          }
          placeholder="Description"
          style={styles.textarea}
        />

        {form.category === "Portfolio" && (
          <div style={styles.costSection}>
            <input
              type="number"
              value={form.cost}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, cost: e.target.value }))
              }
              placeholder="Cost"
              style={styles.input}
            />
            <select
              value={form.currency}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, currency: e.target.value }))
              }
              style={styles.select}
            >
              {currencies.map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>
        )}

        {form.category === "Ideas" && (
          <div style={styles.costSection}>
            <input
              type="number"
              value={form.budget}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, budget: e.target.value }))
              }
              placeholder="Budget"
              style={styles.input}
            />
            <select
              value={form.currency}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, currency: e.target.value }))
              }
              style={styles.select}
            >
              {currencies.map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: "24px",
  },
  exitButton: {
    padding: "8px 16px",
    border: "none",
    background: "none",
    cursor: "pointer",
    fontSize: "16px",
  },
  submitButton: {
    padding: "8px 24px",
    backgroundColor: "#000",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
  },
  submitButtonDisabled: {
    backgroundColor: "#ccc",
    cursor: "not-allowed",
  },
  dropZone: {
    width: "100%",
    minHeight: "300px",
    border: "2px dashed #ccc",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "24px",
    transition: "all 0.3s ease",
  },
  dropZoneActive: {
    borderColor: "#000",
    backgroundColor: "rgba(0,0,0,0.05)",
  },
  dropZoneWithMedia: {
    border: "none",
    backgroundColor: "#f8f9fa",
  },
  dropZoneContent: {
    textAlign: "center",
  },
  dropZoneText: {
    fontSize: "18px",
    color: "#666",
    marginBottom: "8px",
  },
  dropZoneSubtext: {
    fontSize: "14px",
    color: "#999",
  },
  mediaPreview: {
    maxWidth: "100%",
    maxHeight: "400px",
    objectFit: "contain",
  },
  hiddenInput: {
    display: "none",
  },
  formSection: {
    marginBottom: "24px",
  },
  sectionTitle: {
    fontSize: "18px",
    marginBottom: "16px",
  },
  categoryButtons: {
    display: "flex",
    gap: "12px",
    marginBottom: "24px",
  },
  categoryButton: {
    padding: "8px 16px",
    border: "1px solid #ccc",
    borderRadius: "20px",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  categoryButtonActive: {
    backgroundColor: "#000",
    color: "#fff",
    borderColor: "#000",
  },
  input: {
    width: "100%",
    padding: "12px",
    marginBottom: "16px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    fontSize: "16px",
  },
  textarea: {
    width: "100%",
    padding: "12px",
    marginBottom: "16px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    fontSize: "16px",
    minHeight: "120px",
    resize: "vertical",
  },
  costSection: {
    display: "flex",
    gap: "12px",
  },
  select: {
    padding: "12px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    fontSize: "16px",
  },
};

export default Create;
