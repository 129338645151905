// AppLayout.jsx

/**
 * Main application layout component that handles routing and authentication
 * This component serves as the root of your application's routing structure
 */

// Core React and routing imports
import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router, // Provides routing functionality
  Routes, // Container for all Route components
  Route, // Individual route definitions
  Navigate, // Used for redirections
  useLocation, // Hook to access current location
} from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute"; // Custom route for protected pages
import { PublicRoute } from "./PublicRoute"; // Custom route for public pages

// Global state and context
import GlobalProvider from "./context/FirebaseGlobalProvider";

// Eagerly loaded components (important for initial render)
import LoadingScreen from "./LoadingScreen";
import LandingPage from "./LandingPage/LandingPage";
import BlogSection from "./LandingPage/BlogSection";
import BlogPost from "./LandingPage/BlogPost";
import AuthLayout from "./auth/_layout.web";
import TabsLayout from "./tabs/_layout.web";

// GTM Route tracking component
const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Send pageview to Google Tag Manager
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "pageview",
        page: {
          path: location.pathname,
          title: document.title,
          search: location.search,
          url: window.location.href,
        },
      });
    }
  }, [location]);

  return null; // This component doesn't render anything
};

// Rest of your imports...
const ImageGenerator = lazy(() => import("./ImageGenerator.web"));
const UserProfile = lazy(() => import("./UserProfile.web"));
const EditProfile = lazy(() => import("./Edit-Profile.web"));
const InstagramDetail = lazy(() => import("./InstagramDetail.web"));
const Influencers = lazy(() => import("./Influencers.web"));
const YouTubeDetail = lazy(() => import("./YoutubeDetail.web"));
const EmailVerification = lazy(() => import("./auth/email-verification.web"));

import BasketballCamp from "./BasketballCamp/BasketballCamp";
import Registration from "./BasketballCamp/Registration";
import Confirmation from "./BasketballCamp/Confirmation";
import Payment from "./BasketballCamp/CheckOutForm";
import Gallery from "./BasketballCamp/Gallery";
import Sponsorships from "./BasketballCamp/Sponsorships";
import GenericCheckout from "./BasketballCamp/GenericCheckout";
import PaymentConfirmation from "./BasketballCamp/PaymentConfirmation";
import TestPaymentCreation from "./BasketballCamp/TestPayment";
import DEGSPage from "./ClimateTech/Degs";

// Legal pages - lazy loaded since they're not frequently accessed
const TermsOfService = lazy(() => import("./legal/TermsOfService.web"));
const PrivacyPolicy = lazy(() => import("./legal/PrivacyPolicy.web"));
const IntellectualProperty = lazy(() =>
  import("./legal/IntellectualCopyrights.web")
);

// AppContent component that includes the RouteTracker
const AppContent = () => {
  return (
    <>
      <RouteTracker />
      <div className="min-h-screen bg-white flex flex-col">
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            {/* PUBLIC ROUTES - Accessible to all users */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/blog" element={<BlogSection />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/basketball" element={<BasketballCamp />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/sponsorships" element={<Sponsorships />} />
            <Route path="/checkout" element={<GenericCheckout />} />
            <Route
              path="/payment-confirmation"
              element={<PaymentConfirmation />}
            />
            <Route path="/test-payment" element={<TestPaymentCreation />} />
            <Route path="/degs" element={<DEGSPage />} />

            {/* Legal Routes - Also public */}
            <Route path="/termsofservices" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/intellectual-property"
              element={<IntellectualProperty />}
            />

            {/* AUTH ROUTES - For authentication flow */}
            <Route
              path="/auth/*"
              element={
                <PublicRoute>
                  <AuthLayout />
                </PublicRoute>
              }
            />

            {/* PROTECTED ROUTES - Require authentication */}
            <Route
              path="/tabs/*"
              element={
                <ProtectedRoute>
                  <TabsLayout />
                </ProtectedRoute>
              }
            />

            {/* More protected routes */}
            <Route
              path="/image-generator"
              element={
                <ProtectedRoute>
                  <ImageGenerator />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-profile/:userId"
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/edit-profile"
              element={
                <ProtectedRoute>
                  <EditProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/instagram-detail"
              element={
                <ProtectedRoute>
                  <InstagramDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/influencers"
              element={
                <ProtectedRoute>
                  <Influencers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/youtube-detail"
              element={
                <ProtectedRoute>
                  <YouTubeDetail />
                </ProtectedRoute>
              }
            />

            {/* Catch-all route for 404s - redirects to home page */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </div>
    </>
  );
};

const RootLayout = () => {
  return (
    <Router>
      <GlobalProvider>
        <AppContent />
      </GlobalProvider>
    </Router>
  );
};

export default RootLayout;
