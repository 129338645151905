// src/components/video-card/VideoCard.web.jsx
import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import PropTypes from "prop-types";

// Import the separated components
import { Header } from "./Header.web";
import { Footer } from "./Footer.web";
import { MenuOverlay } from "./MenuOverlay.web";
import { MediaContent } from "./MediaContent.web";

const VideoCard = ({
  title,
  creator,
  prompt,
  avatar,
  media,
  category,
  cost,
  budget,
  currency,
  currentUserId,
  creatorId,
  postId,
  onAvatarPress,
  onDeletePress,
  onReportPress,
  isViewable,
  updatePostDetails,
  onUpdateSuccess,
  mediaType,
}) => {
  // console.log("VideoCard initial render:", { title, media, mediaType });

  const [isImage, setIsImage] = useState(mediaType === "image");
  const [mediaDimensions, setMediaDimensions] = useState({
    width: null,
    height: null,
  });
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedPrompt, setEditedPrompt] = useState(prompt);
  const videoRef = useRef(null);
  const [isGloballyMuted, setIsGloballyMuted] = useState(true);

  const handleMuteChange = useCallback((muted) => {
    setIsGloballyMuted(muted);
  }, []);

  const handleSaveChanges = useCallback(async () => {
    try {
      if (!postId) {
        throw new Error("Post ID is required for updates");
      }

      const updatedData = {
        title: editedTitle.trim(),
        prompt: editedPrompt.trim(),
      };

      const updatedPost = await updatePostDetails(postId, updatedData);

      if (updatedPost) {
        onUpdateSuccess?.(updatedPost);
        setIsEditing(false);
        setIsMenuVisible(false);
        alert("Post updated successfully");
      } else {
        throw new Error("Failed to update post");
      }
    } catch (error) {
      console.error("Error updating post:", error);
      alert(error.message || "Failed to update post. Please try again.");
    }
  }, [postId, editedTitle, editedPrompt, updatePostDetails, onUpdateSuccess]);

  const handleImageLoad = useCallback((event) => {
    const { width, height } = event.target;
    if (width && height) {
      setMediaDimensions({ width, height });
    }
  }, []);

  const handleVideoLoad = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setIsImage(mediaType === "image");
    setLoading(false); // Set loading to false after mediaType is determined
  }, [mediaType]);

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <div style={styles.spinner} />
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <Header
        avatar={avatar}
        creator={creator}
        category={category}
        onAvatarPress={onAvatarPress}
        onMenuPress={() => setIsMenuVisible(true)}
      />

      <MenuOverlay
        isVisible={isMenuVisible}
        isUserOwner={currentUserId === creatorId}
        onEdit={() => setIsEditing(true)}
        onDelete={onDeletePress}
        onReport={onReportPress}
        onClose={() => setIsMenuVisible(false)}
      />

      <div style={styles.mediaContainer}>
        <MediaContent
          media={media}
          isImage={isImage}
          mediaDimensions={mediaDimensions}
          onImageLoad={handleImageLoad}
          onVideoLoad={handleVideoLoad}
          videoRef={videoRef}
          isViewable={isViewable}
          category={category}
          cost={cost}
          budget={budget}
          currency={currency}
          isGloballyMuted={isGloballyMuted}
          onMuteChange={handleMuteChange}
        />
      </div>

      <Footer
        title={title}
        prompt={prompt}
        isEditing={isEditing}
        editedTitle={editedTitle}
        editedPrompt={editedPrompt}
        onTitleChange={setEditedTitle}
        onPromptChange={setEditedPrompt}
        onSave={handleSaveChanges}
      />
    </div>
  );
};

VideoCard.propTypes = {
  title: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  media: PropTypes.string,
  mediaType: PropTypes.string.isRequired,

  category: PropTypes.string,
  currentUserId: PropTypes.string,
  creatorId: PropTypes.string.isRequired,
  onAvatarPress: PropTypes.func.isRequired,
  onDeletePress: PropTypes.func.isRequired,
  onReportPress: PropTypes.func.isRequired,
  isViewable: PropTypes.bool.isRequired,
  updatePostDetails: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  onUpdateSuccess: PropTypes.func,
};

const styles = {
  container: {
    marginBottom: "16px",
    padding: "16px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
  },
  spinner: {
    width: "40px",
    height: "40px",
    border: "4px solid #f3f3f3",
    borderTop: "4px solid #3498db",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  mediaContainer: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

export default memo(VideoCard);
