// src/components/video-card/Header.web.jsx
import React, { memo } from "react";

export const Header = memo(
  ({ avatar, creator, category, onAvatarPress, onMenuPress }) => (
    <div style={styles.header}>
      <div style={styles.avatarContainer} onClick={onAvatarPress}>
        <img src={avatar} alt="Avatar" style={styles.avatar} />
        <div style={styles.creatorInfo}>
          <p style={styles.creator}>{creator}</p>
          {category && <span style={styles.category}>{category}</span>}
        </div>
      </div>
      <button onClick={onMenuPress} style={styles.menuButton}>
        ⋮
      </button>
    </div>
  )
);

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    marginRight: "10px",
  },
  creatorInfo: {
    display: "flex",
    flexDirection: "column",
  },
  creator: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  category: {
    fontSize: "14px",
    color: "#666",
  },
  menuButton: {
    background: "none",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
};
