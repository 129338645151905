// src/components/video-card/MenuOverlay.web.jsx
import React, { memo } from "react";

export const MenuOverlay = memo(
  ({ isVisible, isUserOwner, onEdit, onDelete, onReport, onClose }) => {
    if (!isVisible) return null;

    return (
      <div style={styles.overlay} onClick={onClose}>
        <div style={styles.menu} onClick={(e) => e.stopPropagation()}>
          {isUserOwner ? (
            <>
              <button onClick={onEdit} style={styles.menuItem}>
                Edit
              </button>
              <button
                onClick={onDelete}
                style={{ ...styles.menuItem, ...styles.deleteItem }}
              >
                Delete
              </button>
            </>
          ) : (
            <button onClick={onReport} style={styles.menuItem}>
              Report
            </button>
          )}
          <button onClick={onClose} style={styles.menuItem}>
            Close
          </button>
        </div>
      </div>
    );
  }
);

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  menu: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "16px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  menuItem: {
    padding: "10px 16px",
    backgroundColor: "#f9f9f9",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    textAlign: "center",
  },
  deleteItem: {
    backgroundColor: "#ff4d4f",
    color: "#fff",
  },
};
