import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./sign-in.web";
import SignUp from "./sign-up.web";
import ForgotPassword from "./forgot-password.web";
import EmailVerification from "./email-verification.web";

const defaultRoute = "sign-in";

const AuthLayout = () => {
  return (
    <div className="w-full min-h-screen bg-white">
      <div className="w-full">
        <Routes>
          <Route index element={<Navigate to={defaultRoute} replace />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="*" element={<Navigate to={defaultRoute} replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default AuthLayout;