import React from "react";
import { useParams } from "react-router-dom";

// Import the same blog posts data
import { blogPosts } from "../blog/post";
import BurgerMenu from "../components/BurgerMenu";

const BlogPost = () => {
  const { slug } = useParams();
  const post = blogPosts.find((post) => post.slug === slug);

  if (!post) {
    return <div className="container mx-auto px-4 py-12">Post not found</div>;
  }

  return (
    <article className="max-w-4xl mx-auto px-4 py-12">
      {/* Hero Section */}
      <div className="container mx-auto px-4 py-4 flex justify-end">
        <BurgerMenu />
      </div>
      <header className="mb-12">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">{post.title}</h1>
        <time className="text-gray-600">
          {new Date(post.date).toLocaleDateString()}
        </time>
      </header>

      {/* Featured Image */}
      <img
        src={post.image}
        alt={post.title}
        className="w-full h-96 object-cover rounded-lg mb-12"
      />

      {/* Content */}
      <div
        className="prose max-w-none"
        dangerouslySetInnerHTML={{ __html: post.content }}
      />

      {/* Navigation */}
      <div className="mt-12 pt-8 border-t border-gray-200">
        <button
          onClick={() => window.history.back()}
          className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          ← Back to Blog
        </button>
      </div>
    </article>
  );
};

export default BlogPost;
