import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../services/firebase/firebaseInit";

const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

const GlobalProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [needsEmailVerification, setNeedsEmailVerification] = useState(false);

  // Helper function to update user verification status in Firestore
  const updateUserVerificationStatus = async (uid, isVerified) => {
    try {
      const userRef = doc(db, "users", uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists() && userSnap.data().emailVerified !== isVerified) {
        await updateDoc(userRef, { emailVerified: isVerified });
        console.log(
          "Updated user verification status in Firestore:",
          isVerified
        );
      }
    } catch (error) {
      console.error("Error updating verification status:", error);
    }
  };

  // Function to refresh email verification status
  const refreshVerificationStatus = async () => {
    try {
      const currentUser = auth.currentUser;

      if (currentUser) {
        console.log("Reloading user...");
        await currentUser.reload(); // Reload the current user's auth state
        const isVerified = currentUser.emailVerified;

        console.log("Email verification status:", isVerified);

        // Sync with Firestore if verified
        if (isVerified) {
          console.log("Updating Firestore emailVerified status...");
          const userRef = doc(db, "users", currentUser.uid);
          await updateDoc(userRef, { emailVerified: true });

          setUser((prev) => ({ ...prev, emailVerified: true }));
          setNeedsEmailVerification(false);
          setIsLoggedIn(true);
        }

        return isVerified;
      }

      return false;
    } catch (error) {
      console.error("Error refreshing verification status:", error);
      return false;
    }
  };

  // Firebase Authentication listener
  useEffect(() => {
    console.log("Setting up Firebase auth listener...");

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      console.log("Auth State Changed:", {
        userExists: !!firebaseUser,
        emailVerified: firebaseUser?.emailVerified,
        uid: firebaseUser?.uid,
      });

      setIsLoading(true);

      if (firebaseUser) {
        try {
          const userRef = doc(db, "users", firebaseUser.uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = {
              uid: firebaseUser.uid,
              email: firebaseUser.email,
              emailVerified: firebaseUser.emailVerified,
              ...userSnap.data(),
            };
            console.log("User Data:", userData);

            // Update Firestore if verification status has changed
            if (userData.emailVerified !== userSnap.data().emailVerified) {
              await updateUserVerificationStatus(
                firebaseUser.uid,
                userData.emailVerified
              );
            }

            setUser(userData);

            if (firebaseUser.emailVerified) {
              console.log("Email verified, setting user as logged in");
              setNeedsEmailVerification(false);
              setIsLoggedIn(true);
            } else {
              console.log("Email not verified, user needs to verify email");
              setNeedsEmailVerification(true);
              setIsLoggedIn(false);
            }
          } else {
            console.error("User document not found in Firestore.");
            setUser(null);
            setNeedsEmailVerification(false);
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          setUser(null);
          setNeedsEmailVerification(false);
          setIsLoggedIn(false);
        }
      } else {
        console.log("No Firebase User");
        setUser(null);
        setNeedsEmailVerification(false);
        setIsLoggedIn(false);
      }

      setIsLoading(false);
    });

    // Cleanup subscription on unmount
    return () => {
      console.log("Cleaning up Firebase auth listener...");
      unsubscribe();
    };
  }, []);

  // Debugging helper function
  const debugAuthState = () => {
    const currentUser = auth.currentUser;
    console.log("Debug Current State:", {
      firebaseUser: currentUser
        ? {
            uid: currentUser.uid,
            email: currentUser.email,
            emailVerified: currentUser.emailVerified,
          }
        : null,
      contextUser: user,
      isLoggedIn,
      needsEmailVerification,
      isLoading,
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        isLoading,
        needsEmailVerification,
        setNeedsEmailVerification,
        refreshVerificationStatus,
        debugAuthState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
