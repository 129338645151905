import React, { useState } from "react";
import {
  Info,
  ArrowRight,
  Globe,
  Building,
  Wind,
  Sun,
  Database,
  Server,
  Cpu,
  Coffee,
} from "lucide-react";

const DEGSPage = () => {
  const [activeTab, setActiveTab] = useState("overview");

  return (
    <div className="w-full min-h-screen bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-br from-blue-50 to-green-50 text-black w-full">
        <div className="container mx-auto px-4 py-16 md:py-24">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-16">
            <div className="w-full lg:w-1/2 space-y-6">
              <h1 className="text-5xl md:text-4xl lg:text-6xl font-bold">
                HOW ART AND TECHNOLOGY CAN HEAL THE PLANET
              </h1>
              <p className="text-lg md:text-xl text-gray-600">
                The Decentralized and Environmental Grading System (DEGS)
                empowers consumers and businesses with transparency about
                environmental impact and resource control.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <button
                  onClick={() => setActiveTab("overview")}
                  className="bg-gradient-to-r from-blue-500 to-green-500 text-white px-8 py-3 rounded-lg font-semibold hover:opacity-90 transition-all"
                >
                  Learn More
                </button>
              </div>
            </div>
            <div className="w-full lg:w-1/2 relative">
              {/* Decorative background elements */}
              <div className="absolute -inset-4 bg-gradient-to-r from-blue-100 via-green-100 to-teal-100 rounded-xl blur-xl opacity-70"></div>

              {/* Main visual */}
              <div className="relative w-full rounded-xl shadow-xl overflow-hidden bg-white p-6">
                <div className="flex justify-center">
                  <div className="w-64 h-64 relative">
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-56 h-56 rounded-full border-8 border-blue-100 flex items-center justify-center">
                        <div className="text-center">
                          <div className="text-5xl font-bold bg-gradient-to-r from-blue-500 to-green-500 bg-clip-text text-transparent">
                            DEGS
                          </div>
                          <div className="text-sm text-gray-500 mt-2">
                            Scoring System
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="absolute top-0 right-0">
                      <div className="w-16 h-16 rounded-full bg-blue-500 flex items-center justify-center text-white font-bold">
                        100%
                      </div>
                    </div>
                    <div className="absolute bottom-0 left-0">
                      <div className="w-16 h-16 rounded-full bg-green-500 flex items-center justify-center text-white font-bold">
                        100%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-6">
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                    <span className="text-sm">Decentralized Score</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded-full bg-green-500"></div>
                    <span className="text-sm">Environmental Score</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="bg-white border-b">
        <div className="container mx-auto px-4">
          <div className="flex overflow-x-auto">
            <button
              onClick={() => setActiveTab("overview")}
              className={`px-6 py-4 font-medium transition-colors ${
                activeTab === "overview"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-600"
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab("environmental")}
              className={`px-6 py-4 font-medium transition-colors ${
                activeTab === "environmental"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-600"
              }`}
            >
              Environmental Scoring
            </button>
            <button
              onClick={() => setActiveTab("decentralized")}
              className={`px-6 py-4 font-medium transition-colors ${
                activeTab === "decentralized"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-600"
              }`}
            >
              Decentralized Scoring
            </button>
            <button
              onClick={() => setActiveTab("impact")}
              className={`px-6 py-4 font-medium transition-colors ${
                activeTab === "impact"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-600"
              }`}
            >
              Consumer Impact
            </button>
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div className="container mx-auto px-4 py-12">
        {activeTab === "overview" && (
          <div className="space-y-12">
            <div className="max-w-3xl mx-auto text-center space-y-4">
              <h2 className="text-3xl font-bold">
                Decentralized and Environmental Grading System
              </h2>
              <p className="text-lg text-gray-600">
                DEGS is a revolutionary scoring system that measures both the
                environmental impact and decentralization level of businesses,
                providing transparency to consumers and encouraging sustainable
                practices.
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              <FeatureCard
                icon={<Globe className="w-6 h-6" />}
                title="Environmental Impact"
                description="Track and visualize how businesses use renewable vs. non-renewable energy sources to operate."
              />
              <FeatureCard
                icon={<Building className="w-6 h-6" />}
                title="Resource Control"
                description="Measure how decentralized a company is based on their control over essential resources."
              />
              <FeatureCard
                icon={<Coffee className="w-6 h-6" />}
                title="Consumer Power"
                description="Empower consumers with information to make better purchasing decisions for the planet."
              />
            </div>

            <div className="bg-gray-50 rounded-xl p-8 max-w-4xl mx-auto">
              <div className="flex flex-col md:flex-row gap-8 items-center">
                <div className="w-full md:w-1/2">
                  <h3 className="text-2xl font-bold mb-4">
                    It Starts With The Food
                  </h3>
                  <p className="text-gray-600 mb-4">
                    Making this color and number available to consumers when
                    they purchase online educates them on how their buying
                    decisions affect the planet.
                  </p>
                  <p className="text-gray-600">
                    People should know if their choices are impacting the
                    environment, allowing them to invest their time, energy, and
                    currency more wisely.
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <div className="bg-white rounded-lg shadow-lg p-6">
                    <div className="flex items-center gap-4 mb-6">
                      <div className="w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center text-white font-bold text-sm">
                        86%
                      </div>
                      <div>
                        <p className="font-medium">Decentralized Score</p>
                        <p className="text-sm text-gray-500">
                          Control of essential resources
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="w-12 h-12 rounded-full bg-green-500 flex items-center justify-center text-white font-bold text-sm">
                        75%
                      </div>
                      <div>
                        <p className="font-medium">Environmental Score</p>
                        <p className="text-sm text-gray-500">
                          Clean energy usage
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === "environmental" && (
          <div className="space-y-8 max-w-4xl mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-4">
                Environmental Scoring System
              </h2>
              <p className="text-lg text-gray-600">
                The Environmental Score is based on the amount of renewable and
                non-renewable energy used to produce goods or services, averaged
                together.
              </p>
            </div>

            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="p-6">
                <h3 className="text-xl font-bold mb-4">Clean Energy Key</h3>
                <div className="space-y-4">
                  <ScoreRow color="bg-blue-500" range="76-100" label="Clean" />
                  <ScoreRow color="bg-cyan-500" range="51-75" label="Clean" />
                  <ScoreRow color="bg-red-600" range="26-50" label="Clean" />
                  <ScoreRow color="bg-yellow-800" range="0-25" label="Clean" />
                </div>
              </div>
              <div className="bg-gray-50 p-6">
                <h3 className="text-xl font-bold mb-4">
                  Energy Source Ratings
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-bold mb-2">Renewable/Clean</h4>
                    <ul className="space-y-2 text-gray-600">
                      <li className="flex justify-between">
                        <span>Solar</span>
                        <span>100</span>
                      </li>
                      <li className="flex justify-between">
                        <span>Wind</span>
                        <span>100</span>
                      </li>
                      <li className="flex justify-between">
                        <span>Hydro</span>
                        <span>100</span>
                      </li>
                      <li className="flex justify-between">
                        <span>Geothermal</span>
                        <span>100</span>
                      </li>
                      <li className="flex justify-between">
                        <span>Biomass Energy</span>
                        <span>100</span>
                      </li>
                      <li className="flex justify-between">
                        <span>Nuclear</span>
                        <span>100</span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-bold mb-2">Non-Renewable</h4>
                    <ul className="space-y-2 text-gray-600">
                      <li className="flex justify-between">
                        <span>Fossil Fuel</span>
                        <span>0</span>
                      </li>
                      <li className="flex justify-between">
                        <span>Coal</span>
                        <span>0</span>
                      </li>
                      <li className="flex justify-between">
                        <span>Natural Gas</span>
                        <span>0</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-6 pt-6 border-t border-gray-200">
                  <div className="text-sm text-gray-600">
                    <p className="font-medium">Formula:</p>
                    <p>
                      (Sum of Non-renewable Energy) + (Sum of Renewable Energy)
                      / (Amount of Energy Resources)
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-6 mt-8">
              <h3 className="text-xl font-bold mb-4">Example Calculation</h3>
              <p className="text-gray-600 mb-4">
                Let's say a company uses multiple energy sources across their
                operations:
              </p>
              <ul className="list-disc pl-5 mb-4 text-gray-600">
                <li>Solar panels at headquarters: 100 score</li>
                <li>Natural gas for manufacturing: 0 score</li>
                <li>Wind energy for data centers: 100 score</li>
              </ul>
              <p className="text-gray-600 mb-2">
                Their environmental score would be:
              </p>
              <p className="font-medium">
                (100 + 0 + 100) / 3 = 66.7 (51-75 Clean)
              </p>
            </div>
          </div>
        )}

        {activeTab === "decentralized" && (
          <div className="space-y-8 max-w-4xl mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-4">
                Decentralized Scoring System
              </h2>
              <p className="text-lg text-gray-600">
                The Decentralized Score is calculated by the amount of essential
                resources needed to provide a good or service that an entity has
                complete control over divided by the total number of essential
                resources.
              </p>
            </div>

            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="p-6">
                <h3 className="text-xl font-bold mb-4">
                  Essential Business Resources
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  <ResourceCard
                    icon={<Server className="w-6 h-6" />}
                    title="Data Center"
                  />
                  <ResourceCard
                    icon={<Wind className="w-6 h-6" />}
                    title="Power Grid"
                  />
                  <ResourceCard
                    icon={<Database className="w-6 h-6" />}
                    title="Software"
                  />
                  <ResourceCard
                    icon={<Cpu className="w-6 h-6" />}
                    title="Devices"
                  />
                  <ResourceCard
                    icon={<Building className="w-6 h-6" />}
                    title="Workplace"
                  />
                  <ResourceCard
                    icon={<Globe className="w-6 h-6" />}
                    title="Network Tower"
                  />
                  <ResourceCard
                    icon={<ArrowRight className="w-6 h-6" />}
                    title="Payment System"
                  />
                </div>
              </div>
              <div className="bg-gray-50 p-6">
                <h3 className="text-xl font-bold mb-4">Formula</h3>
                <div className="text-gray-600">
                  <p className="font-medium">Decentralized Score:</p>
                  <p className="mb-4">
                    (Amount of Controlled Essential Resources) / (Amount of
                    Controlled and Non-controlled Essential Resources) × 100%
                  </p>
                </div>

                <div className="mt-6">
                  <h4 className="font-bold mb-2">Example Calculation:</h4>
                  <p className="text-gray-600 mb-2">
                    Company B has control over:
                  </p>
                  <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Data Center</li>
                    <li>Network Tower</li>
                  </ul>
                  <p className="text-gray-600 mb-2">But lacks control over:</p>
                  <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Power Grid</li>
                    <li>Devices</li>
                    <li>Office/Store</li>
                    <li>Software</li>
                    <li>Payment System</li>
                  </ul>
                  <p className="font-medium">
                    Decentralized Score: 2 ÷ 7 = 29%
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-6 mt-8">
              <h3 className="text-xl font-bold mb-4">
                Why Decentralization Matters
              </h3>
              <p className="text-gray-600 mb-4">
                A higher decentralization score indicates greater independence
                and resilience. Companies with higher scores:
              </p>
              <ul className="list-disc pl-5 mb-4 text-gray-600">
                <li>Have more control over their environmental impact</li>
                <li>Can implement sustainable practices more effectively</li>
                <li>Are less vulnerable to external disruptions</li>
                <li>Contribute to a more robust and democratic economy</li>
              </ul>
            </div>
          </div>
        )}

        {activeTab === "impact" && (
          <div className="space-y-8 max-w-4xl mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-4">Consumer Impact</h2>
              <p className="text-lg text-gray-600">
                Making DEGS scores visible to consumers transforms buying
                decisions and encourages businesses to adopt more sustainable
                and decentralized practices.
              </p>
            </div>

            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="p-6">
                <h3 className="text-xl font-bold mb-4">
                  Benefits for Consumers
                </h3>
                <ul className="space-y-4">
                  <li className="flex gap-3">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center text-blue-500">
                      1
                    </div>
                    <div>
                      <p className="font-medium">Education</p>
                      <p className="text-gray-600">
                        Consumers learn how their buying decisions affect the
                        planet.
                      </p>
                    </div>
                  </li>
                  <li className="flex gap-3">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center text-blue-500">
                      2
                    </div>
                    <div>
                      <p className="font-medium">Context</p>
                      <p className="text-gray-600">
                        Consumers can understand why a company might have a low
                        environmental score due to limited resource control.
                      </p>
                    </div>
                  </li>
                  <li className="flex gap-3">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center text-blue-500">
                      3
                    </div>
                    <div>
                      <p className="font-medium">Transparency</p>
                      <p className="text-gray-600">
                        Clear visual indicators help consumers make quick yet
                        informed decisions.
                      </p>
                    </div>
                  </li>
                  <li className="flex gap-3">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center text-blue-500">
                      4
                    </div>
                    <div>
                      <p className="font-medium">Empowerment</p>
                      <p className="text-gray-600">
                        Consumers can direct their spending toward businesses
                        that align with their values.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="p-6 border-t border-gray-200">
                <h3 className="text-xl font-bold mb-4">Integration Example</h3>
                <div className="bg-gray-100 rounded-lg p-4 mb-6">
                  <div className="mb-3">
                    <h4 className="font-medium text-gray-800">
                      Store Listings with DEGS Scores
                    </h4>
                  </div>
                  <div className="space-y-4">
                    <div className="bg-white rounded-lg p-3 flex justify-between items-center">
                      <div className="flex items-center gap-3">
                        <div className="w-12 h-12 bg-green-100 rounded-lg"></div>
                        <div>
                          <p className="font-medium">Simpli Market</p>
                          <p className="text-sm text-gray-500">
                            2 km • 1 Hour Delivery
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="w-8 h-8 rounded-full border flex items-center justify-center text-xs">
                          50%
                        </div>
                        <div className="w-8 h-8 rounded-full bg-red-600 flex items-center justify-center text-white text-xs">
                          30%
                        </div>
                      </div>
                    </div>
                    <div className="bg-white rounded-lg p-3 flex justify-between items-center">
                      <div className="flex items-center gap-3">
                        <div className="w-12 h-12 bg-blue-100 rounded-lg"></div>
                        <div>
                          <p className="font-medium">Mega Store</p>
                          <p className="text-sm text-gray-500">
                            3 km • 2 Hour Delivery
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="w-8 h-8 rounded-full border flex items-center justify-center text-xs">
                          29%
                        </div>
                        <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs">
                          85%
                        </div>
                      </div>
                    </div>
                    <div className="bg-white rounded-lg p-3 flex justify-between items-center">
                      <div className="flex items-center gap-3">
                        <div className="w-12 h-12 bg-red-100 rounded-lg"></div>
                        <div>
                          <p className="font-medium">Village Market</p>
                          <p className="text-sm text-gray-500">
                            5 km • 2 Hour Delivery
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="w-8 h-8 rounded-full border flex items-center justify-center text-xs">
                          75%
                        </div>
                        <div className="w-8 h-8 rounded-full bg-cyan-500 flex items-center justify-center text-white text-xs">
                          65%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-gray-600 text-sm">
                  When integrated into purchasing platforms, DEGS scores allow
                  consumers to consider sustainability alongside traditional
                  factors like price, proximity, and delivery time.
                </p>
              </div>
            </div>

            <div className="bg-gradient-to-r from-blue-500 to-green-500 rounded-xl shadow-lg p-8 text-white">
              <h3 className="text-2xl font-bold mb-4">
                The Vision: A Sustainable Future
              </h3>
              <p className="mb-4">As DEGS adoption grows, we envision:</p>
              <ul className="space-y-2 mb-6">
                <li className="flex items-start gap-2">
                  <div className="mt-1 flex-shrink-0">•</div>
                  <p>
                    A marketplace where environmental and decentralization
                    metrics are as standard as price tags
                  </p>
                </li>
                <li className="flex items-start gap-2">
                  <div className="mt-1 flex-shrink-0">•</div>
                  <p>
                    Businesses competing to improve their DEGS scores to attract
                    conscious consumers
                  </p>
                </li>
                <li className="flex items-start gap-2">
                  <div className="mt-1 flex-shrink-0">•</div>
                  <p>
                    A global map of sustainability that itself becomes a work of
                    art
                  </p>
                </li>
                <li className="flex items-start gap-2">
                  <div className="mt-1 flex-shrink-0">•</div>
                  <p>
                    Enhanced democracy through decentralized control of
                    essential resources
                  </p>
                </li>
              </ul>
              <button className="bg-white text-blue-500 px-6 py-2 rounded-lg font-medium hover:bg-blue-50 transition-colors">
                Join The Movement
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Call to Action */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center space-y-6">
            <h2 className="text-3xl font-bold">
              Ready to Calculate Your DEGS Score?
            </h2>
            <p className="text-lg text-gray-600">
              Discover how your business or lifestyle measures up in terms of
              environmental impact and decentralization.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button className="bg-gradient-to-r from-blue-500 to-green-500 text-white px-8 py-3 rounded-lg font-semibold hover:opacity-90 transition-all">
                Get Your Score
              </button>
              <button className="bg-white text-gray-800 border border-gray-300 px-8 py-3 rounded-lg font-semibold hover:bg-gray-50 transition-all">
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <h3 className="font-bold text-lg mb-4">DEGS Initiative</h3>
              <p className="text-gray-400">A project by Donnavan Kirk</p>
            </div>
            <div>
              <h4 className="font-bold mb-4">Resources</h4>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="text-gray-400 hover:text-white">
                    Documentation
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-400 hover:text-white">
                    Case Studies
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-400 hover:text-white">
                    Research
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-bold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="/privacy-policy"
                    className="text-gray-400 hover:text-white"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="/termsofservices"
                    className="text-gray-400 hover:text-white"
                  >
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-bold mb-4">Connect</h4>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-white">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>&copy; 2025 DEGS Initiative. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

// Utility Components
const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white rounded-xl shadow-lg p-6 h-full transition-all hover:shadow-xl">
    <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center text-blue-600 mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const ResourceCard = ({ icon, title }) => (
  <div className="bg-gray-100 rounded-lg p-4 flex items-center gap-3">
    <div className="w-10 h-10 bg-blue-100 rounded-lg flex items-center justify-center text-blue-500">
      {icon}
    </div>
    <p className="font-medium">{title}</p>
  </div>
);

const ScoreRow = ({ color, range, label }) => (
  <div className="flex items-center gap-4">
    <div className={`w-8 h-8 rounded-full ${color}`}></div>
    <span className="font-medium">
      {range} {label}
    </span>
  </div>
);

export default DEGSPage;
