import profile from "../assets/images/profile.png";
import thumbnail from "../assets/images/thumbnail.png";
import cards from "../assets/images/cards.png";
import path from "../assets/images/path.png";
import logo from "../assets/images/cc_fullname.png";
import logoSmall from "../assets/images/logo-small.png";
import empty from "../assets/images/empty.png";
import appImage1 from "../assets/images/IMG_2377.PNG";
import basketballCamp from "../assets/images/basketballCamp.png";
// Add new BasketballCamp image imports
import image1 from "../assets/images/BasketballCamp/image1.png";
import image3 from "../assets/images/BasketballCamp/image3.png";
import image4 from "../assets/images/BasketballCamp/image4.png";
import image10 from "../assets/images/BasketballCamp/image10.png";
import image11 from "../assets/images/BasketballCamp/image11.png";
import image12 from "../assets/images/BasketballCamp/image12.png";
import image13 from "../assets/images/BasketballCamp/image13.png";
import image14 from "../assets/images/BasketballCamp/image14.png";
import image15 from "../assets/images/BasketballCamp/image15.png";
import image16 from "../assets/images/BasketballCamp/image16.png";
import image17 from "../assets/images/BasketballCamp/image17.png";
import image18 from "../assets/images/BasketballCamp/image18.png";
import image19 from "../assets/images/BasketballCamp/image19.png";
import image20 from "../assets/images/BasketballCamp/image20.png";
import image21 from "../assets/images/BasketballCamp/image21.png";
import image22 from "../assets/images/BasketballCamp/image22.png";
import image23 from "../assets/images/BasketballCamp/image23.png";
import image24 from "../assets/images/BasketballCamp/image24.png";
import image25 from "../assets/images/BasketballCamp/image25.png";
import image26 from "../assets/images/BasketballCamp/image26.png";
import image27 from "../assets/images/BasketballCamp/image27.png";
import image28 from "../assets/images/BasketballCamp/image28.png";
import image29 from "../assets/images/BasketballCamp/image29.png";
import image30 from "../assets/images/BasketballCamp/image30.png";
import image31 from "../assets/images/BasketballCamp/image31.png";
import image32 from "../assets/images/BasketballCamp/image32.png";
import image33 from "../assets/images/BasketballCamp/image33.png";
import image34 from "../assets/images/BasketballCamp/image34.png";
import image35 from "../assets/images/BasketballCamp/image35.png";
import image36 from "../assets/images/BasketballCamp/image36.png";
import image37 from "../assets/images/BasketballCamp/image37.png";
import image38 from "../assets/images/BasketballCamp/image38.png";
import image39 from "../assets/images/BasketballCamp/image39.png";
import image40 from "../assets/images/BasketballCamp/image40.png";
import image41 from "../assets/images/BasketballCamp/image41.png";
import image42 from "../assets/images/BasketballCamp/image42.png";
import image43 from "../assets/images/BasketballCamp/image43.png";
import image44 from "../assets/images/BasketballCamp/image44.png";
import image45 from "../assets/images/BasketballCamp/image45.png";
import image46 from "../assets/images/BasketballCamp/image46.png";
import image47 from "../assets/images/BasketballCamp/image47.png";
import image48 from "../assets/images/BasketballCamp/image48.png";
import image49 from "../assets/images/BasketballCamp/image49.png";
import image50 from "../assets/images/BasketballCamp/image50.png";
import image51 from "../assets/images/BasketballCamp/image51.png";
import image52 from "../assets/images/BasketballCamp/image52.png";
import image53 from "../assets/images/BasketballCamp/image53.png";
import image54 from "../assets/images/BasketballCamp/image54.png";
import image55 from "../assets/images/BasketballCamp/image55.png";
import image56 from "../assets/images/BasketballCamp/image56.png";
import image57 from "../assets/images/BasketballCamp/image57.png";
import image58 from "../assets/images/BasketballCamp/image58.png";
import image59 from "../assets/images/BasketballCamp/image59.png";
import image60 from "../assets/images/BasketballCamp/image60.png";
import image61 from "../assets/images/BasketballCamp/image61.png";
import image62 from "../assets/images/BasketballCamp/image62.png";
import image63 from "../assets/images/BasketballCamp/image63.png";
import image64 from "../assets/images/BasketballCamp/image64.png";
import image65 from "../assets/images/BasketballCamp/image65.png";
import image66 from "../assets/images/BasketballCamp/image66.png";
import image67 from "../assets/images/BasketballCamp/image67.png";
import image68 from "../assets/images/BasketballCamp/image68.png";
import image69 from "../assets/images/BasketballCamp/image69.png";
import image70 from "../assets/images/BasketballCamp/image70.png";
import image71 from "../assets/images/BasketballCamp/image71.png";
import image72 from "../assets/images/BasketballCamp/image72.png";
import image73 from "../assets/images/BasketballCamp/image73.png";
import image74 from "../assets/images/BasketballCamp/image74.png";
import image75 from "../assets/images/BasketballCamp/image75.png";
import image76 from "../assets/images/BasketballCamp/image76.png";
import image77 from "../assets/images/BasketballCamp/image77.png";
import image78 from "../assets/images/BasketballCamp/image78.png";
import image79 from "../assets/images/BasketballCamp/image79.png";
import image80 from "../assets/images/BasketballCamp/image80.png";
import image81 from "../assets/images/BasketballCamp/image81.png";
import image82 from "../assets/images/BasketballCamp/image82.png";
import image83 from "../assets/images/BasketballCamp/image83.png";
import image84 from "../assets/images/BasketballCamp/image84.png";
import image85 from "../assets/images/BasketballCamp/image85.png";
import gym from "../assets/images/gym.jpg";


export default {
    profile,
    thumbnail,
    cards,
    path,
    logo,
    logoSmall,
    empty,
    appImage1,
    basketballCamp,
    image1,
    image3,
    image4,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
    image28,
    image29,
    image30,
    image31,
    image32,
    image33,
    image34,
    image35,
    image36,
    image37,
    image38,
    image39,
    image40,
    image41,
    image42,
    image43,
    image44,
    image45,
    image46,
    image47,
    image48,
    image49,
    image50,
    image51,
    image52,
    image53,
    image54,
    image55,
    image56,
    image57,
    image58,
    image59,
    image60,
    image61,
    image62,
    image63,
    image64,
    image65,
    image66,
    image67,
    image68,
    image69,
    image70,
    image71,
    image72,
    image73,
    image74,
    image75,
    image76,
    image77,
    image78,
    image79,
    image80,
    image81,
    image82,
    image83,
    image84,
    image85,
    gym
  };