// PublicRoute.jsx
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useGlobalContext } from "./context/FirebaseGlobalProvider";

export const PublicRoute = ({ children }) => {
  const { isLoggedIn } = useGlobalContext();
  const location = useLocation();

  // If user is logged in and tries to access auth pages, redirect to /tabs
  if (isLoggedIn && location.pathname.startsWith("/auth")) {
    return <Navigate to="/tabs" replace />;
  }

  return children;
};
