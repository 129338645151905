// src/index.js
import React from "react";
import { createRoot } from "react-dom/client";
import RootLayout from "./_layout.web";
import GlobalProvider from "./context/FirebaseGlobalProvider";
import { StripeProvider } from "./context/StripeContext";
import { requestNotificationPermission } from "./services/firebase/notifications";
import { auth } from "./services/firebase/firebase";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

// Initialize notifications
const initializeNotifications = async () => {
  try {
    const token = await requestNotificationPermission();
    if (token) {
      console.log("FCM Token:", token);
    }
  } catch (error) {
    console.error("Error initializing notifications:", error);
  }
};

// Initialize Firebase Auth Listener
const initializeAuthListener = () => {
  auth.onAuthStateChanged((user) => {
    if (user) {
      console.log("User signed in:", {
        uid: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
      });
    } else {
      console.log("No user signed in.");
    }
  });
};

// Initialize the app
const initializeApp = () => {
  initializeNotifications();
  initializeAuthListener();
};

// Call initialization functions
initializeApp();

// Removed BrowserRouter since it's likely in RootLayout
root.render(
  <React.StrictMode>
    <GlobalProvider>
      <StripeProvider>
        {" "}
        {/* Wrap RootLayout with StripeProvider */}
        <RootLayout />
      </StripeProvider>
    </GlobalProvider>
  </React.StrictMode>
);
