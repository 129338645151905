import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

// Load Stripe outside component
const stripePromise = loadStripe(
  "pk_test_51Qi17ERpL5fTJyho5Vv52UDmo89r4xkOKS09crGHyLvrAtlqq0jrHpGWQ9a4B6opzPl8cDboru4s5oEGkiwNgJM000gOrI2Shs"
);

const TestPaymentCreation = () => {
  const [status, setStatus] = useState("idle");
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(null);

  const testPaymentIntent = async () => {
    try {
      setStatus("loading");
      setError(null);

      // Get Firebase Functions instance
      const functions = getFunctions();

      // Create reference to the function
      const createDonationPayment = httpsCallable(
        functions,
        "createDonationPayment"
      );

      // Call with hardcoded test values
      const result = await createDonationPayment({
        amount: 50, // Hardcoded test amount
        type: "donation",
        metadata: {},
      });

      console.log("Function result:", result.data);
      setClientSecret(result.data.paymentIntent);
      setStatus("success");
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || "An unknown error occurred");
      setStatus("error");
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Test Payment Intent Creation</h2>

      {status === "idle" && (
        <button
          onClick={testPaymentIntent}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Test With Hardcoded Values ($50)
        </button>
      )}

      {status === "loading" && <div className="text-center">Loading...</div>}

      {status === "error" && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <strong>Error: </strong> {error}
          <button
            onClick={() => setStatus("idle")}
            className="mt-3 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-sm"
          >
            Try Again
          </button>
        </div>
      )}

      {status === "success" && clientSecret && (
        <div>
          <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
            Payment intent created successfully!
          </div>

          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentForm />
          </Elements>
        </div>
      )}
    </div>
  );
};

// Simple payment form once we have the client secret
const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + "/payment-success",
        },
        redirect: "if_required",
      });

      if (error) {
        setPaymentError(error.message);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        setPaymentSuccess(true);
      }
    } catch (err) {
      setPaymentError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  if (paymentSuccess) {
    return (
      <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
        Payment successful! Thank you for your donation.
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      {paymentError && (
        <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {paymentError}
        </div>
      )}

      <button
        type="submit"
        disabled={!stripe || isProcessing}
        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
      >
        {isProcessing ? "Processing..." : "Pay $50"}
      </button>
    </form>
  );
};

export default TestPaymentCreation;
