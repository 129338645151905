// src/app/(tabs)/notification.web.jsx
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../context/FirebaseGlobalProvider";
import {
  getFirestore,
  collection,
  doc,
  query,
  orderBy,
  onSnapshot,
  getDoc,
} from "firebase/firestore";

const NotificationItem = ({ notification }) => {
  return (
    <div style={styles.notificationItem}>
      <img
        src={notification.avatar || "/default-avatar.png"}
        alt="User avatar"
        style={styles.avatar}
      />
      <div style={styles.notificationContent}>
        <p style={styles.message}>{notification.message}</p>
        <span style={styles.timestamp}>{notification.timestamp}</span>
      </div>
      {notification.postThumbnail && (
        <img
          src={notification.postThumbnail}
          alt="Post thumbnail"
          style={styles.thumbnail}
        />
      )}
    </div>
  );
};

const Notification = () => {
  const { user } = useGlobalContext();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const db = getFirestore();

  useEffect(() => {
    if (!user?.$id) return;

    const notificationsRef = collection(db, "users", user.$id, "notifications");
    const notificationsQuery = query(
      notificationsRef,
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(notificationsQuery, async (snapshot) => {
      try {
        const notificationsData = await Promise.all(
          snapshot.docs.map(async (document) => {
            const data = document.data();
            let fromUserData = {};

            // Fetch user data for the person who performed the action
            if (data.fromUserId) {
              const fromUserDoc = await getDoc(
                doc(db, "users", data.fromUserId)
              );
              fromUserData = fromUserDoc.data() || {};
            }

            // Format the timestamp
            const timestamp = data.timestamp?.toDate();
            const timeAgo = timestamp ? formatTimeAgo(timestamp) : "";

            return {
              id: document.id,
              message:
                data.type === "follow"
                  ? `${fromUserData.username || "Someone"} followed you`
                  : "New notification",
              timestamp: timeAgo,
              avatar: fromUserData.avatar || "/default-avatar.png",
              postThumbnail: data.postThumbnail || null,
              type: data.type,
              read: data.read,
            };
          })
        );

        setNotifications(notificationsData);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [user]);

  // Helper function to format timestamp
  const formatTimeAgo = (date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 0) {
      return `${diffInDays}d ago`;
    } else if (diffInHours > 0) {
      return `${diffInHours}h ago`;
    } else if (diffInMinutes > 0) {
      return `${diffInMinutes}m ago`;
    } else {
      return "Just now";
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Notifications</h1>

      {isLoading ? (
        <div style={styles.loadingContainer}>
          <div style={styles.spinner} />
        </div>
      ) : notifications.length === 0 ? (
        <div style={styles.emptyContainer}>
          <p style={styles.emptyText}>No notifications yet</p>
        </div>
      ) : (
        <div style={styles.notificationsList}>
          {notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#fff",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "24px",
    color: "#1a1a1a",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
  },
  spinner: {
    width: "40px",
    height: "40px",
    border: "4px solid #f3f3f3",
    borderTop: "4px solid #3498db",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  emptyContainer: {
    textAlign: "center",
    padding: "40px",
  },
  emptyText: {
    color: "#666",
    fontSize: "16px",
  },
  notificationsList: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  notificationItem: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    backgroundColor: "#f8f9fa",
    borderRadius: "12px",
    gap: "16px",
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    objectFit: "cover",
  },
  notificationContent: {
    flex: 1,
  },
  message: {
    margin: 0,
    fontSize: "14px",
    color: "#1a1a1a",
  },
  timestamp: {
    fontSize: "12px",
    color: "#666",
  },
  thumbnail: {
    width: "50px",
    height: "50px",
    borderRadius: "8px",
    objectFit: "cover",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

export default Notification;
