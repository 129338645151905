// src/app/(tabs)/search.web.jsx
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/FirebaseGlobalProvider";
import VideoCard from "../components/mediaComponents/VideoCard.Web";
import SearchInput from "../components/SearchInput.web";
import {
  getFirestore,
  collection,
  query as firestoreQuery, // Rename the import
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";

const POSTS_PER_PAGE = 10;
const categories = ["Portfolio", "Ideas", "Moodboard"];

const Search = () => {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const db = getFirestore();

  const [selectedCategory, setSelectedCategory] = useState("Portfolio");
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [postsByCategory, setPostsByCategory] = useState({
    Portfolio: [],
    Ideas: [],
    Moodboard: [],
  });
  const [lastVisibleByCategory, setLastVisibleByCategory] = useState({
    Portfolio: null,
    Ideas: null,
    Moodboard: null,
  });
  const [isGloballyMuted, setIsGloballyMuted] = useState(true);

  const observerRef = useRef(null);
  const loadingTriggerRef = useRef(null);

  useEffect(() => {
    loadInitialPosts(selectedCategory);

    // Setup Intersection Observer for infinite scroll
    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingMore && hasMore) {
          loadMorePosts();
        }
      },
      { threshold: 0.1 }
    );

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [selectedCategory]);

  useEffect(() => {
    if (loadingTriggerRef.current && observerRef.current) {
      observerRef.current.observe(loadingTriggerRef.current);
    }
  }, [postsByCategory[selectedCategory]]);

  const fetchPosts = async (category, isInitial = true) => {
    try {
      let baseQuery = firestoreQuery(
        collection(db, "videos"),
        where("category", "==", category),
        orderBy("createdAt", "desc"),
        limit(POSTS_PER_PAGE)
      );

      if (!isInitial && lastVisibleByCategory[category]) {
        baseQuery = firestoreQuery(
          baseQuery,
          startAfter(lastVisibleByCategory[category])
        );
      }

      const snapshot = await getDocs(baseQuery);

      if (snapshot.empty) {
        return { posts: [], hasMore: false };
      }

      const posts = await Promise.all(
        snapshot.docs.map(async (document) => {
          const postData = document.data();
          const creatorDoc = await getDoc(doc(db, "users", postData.creator));

          return {
            $id: document.id,
            ...postData,
            creator: {
              $id: postData.creator,
              ...creatorDoc.data(),
            },
          };
        })
      );

      return {
        posts,
        lastVisible: snapshot.docs[snapshot.docs.length - 1],
        hasMore: posts.length === POSTS_PER_PAGE,
      };
    } catch (error) {
      console.error("Error fetching posts:", error);
      return { posts: [], hasMore: false };
    }
  };

  const loadInitialPosts = async (category) => {
    setIsLoading(true);
    try {
      const { posts, lastVisible, hasMore } = await fetchPosts(category, true);

      setPostsByCategory((prev) => ({
        ...prev,
        [category]: posts,
      }));

      setLastVisibleByCategory((prev) => ({
        ...prev,
        [category]: lastVisible,
      }));

      setHasMore(hasMore);
    } catch (error) {
      console.error("Error loading initial posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMorePosts = async () => {
    if (isLoadingMore || !hasMore) return;

    setIsLoadingMore(true);
    try {
      const {
        posts,
        lastVisible,
        hasMore: moreAvailable,
      } = await fetchPosts(selectedCategory, false);

      setPostsByCategory((prev) => ({
        ...prev,
        [selectedCategory]: [...prev[selectedCategory], ...posts],
      }));

      setLastVisibleByCategory((prev) => ({
        ...prev,
        [selectedCategory]: lastVisible,
      }));

      setHasMore(moreAvailable);
    } catch (error) {
      console.error("Error loading more posts:", error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    if (!postsByCategory[category].length) {
      loadInitialPosts(category);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      await deleteDoc(doc(db, "videos", postId));
      setPostsByCategory((prev) => ({
        ...prev,
        [selectedCategory]: prev[selectedCategory].filter(
          (post) => post.$id !== postId
        ),
      }));
      alert("Post deleted successfully");
    } catch (error) {
      console.error("Delete error:", error);
      alert("Failed to delete post");
    }
  };

  const updatePostDetails = async (postId, updates) => {
    try {
      await updateDoc(doc(db, "videos", postId), updates);
      setPostsByCategory((prev) => ({
        ...prev,
        [selectedCategory]: prev[selectedCategory].map((post) =>
          post.$id === postId ? { ...post, ...updates } : post
        ),
      }));
      return true;
    } catch (error) {
      console.error("Update error:", error);
      return false;
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.searchSection}>
        <SearchInput
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search..."
          style={styles.searchInput}
        />
      </div>

      <div style={styles.categoriesWrapper}>
        <div style={styles.categoriesContainer}>
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => handleCategorySelect(category)}
              style={{
                ...styles.categoryButton,
                ...(selectedCategory === category &&
                  styles.selectedCategoryButton),
              }}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      <div style={styles.postsContainer}>
        {isLoading ? (
          <div style={styles.loadingContainer}>Loading...</div>
        ) : postsByCategory[selectedCategory].length === 0 ? (
          <div style={styles.emptyState}>
            <h3>No {selectedCategory} Found</h3>
            <p>Try adjusting your search or filters</p>
          </div>
        ) : (
          postsByCategory[selectedCategory].map((post) => (
            <VideoCard
              key={post.$id}
              title={post.title}
              creator={post.creator.username || "Unknown Creator"}
              avatar={post.creator.avatar}
              media={post.media}
              category={post.category}
              prompt={post.prompt}
              currentUserId={user?.$id}
              creatorId={post.creator.$id}
              postId={post.$id}
              cost={post.cost}
              mediaType={post.mediaType} // Add this line
              budget={post.budget}
              currency={post.currency}
              onAvatarPress={() =>
                navigate(
                  post.creator.$id === user?.$id
                    ? "/tabs/profile"
                    : `/user-profile/${post.creator.$id}`
                )
              }
              onDeletePress={() => handleDeletePost(post.$id)}
              showDelete={user && post.creator.$id === user.$id}
              isGloballyMuted={isGloballyMuted}
              onMuteChange={setIsGloballyMuted}
              updatePostDetails={updatePostDetails}
            />
          ))
        )}

        <div ref={loadingTriggerRef} style={styles.loadingTrigger}>
          {isLoadingMore && (
            <div style={styles.loadingMore}>Loading more...</div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#fff",
  },
  searchSection: {
    marginBottom: "24px",
  },
  searchInput: {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #ddd",
    fontSize: "16px",
  },
  categoriesWrapper: {
    marginBottom: "24px",
  },
  categoriesContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "12px",
  },
  categoryButton: {
    padding: "8px 16px",
    borderRadius: "20px",
    border: "none",
    backgroundColor: "#f0f0f0",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500",
    color: "#666",
  },
  selectedCategoryButton: {
    backgroundColor: "#6366F1",
    color: "#fff",
  },
  postsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  loadingContainer: {
    textAlign: "center",
    padding: "40px",
    color: "#666",
  },
  emptyState: {
    textAlign: "center",
    padding: "40px",
    color: "#666",
  },
  loadingTrigger: {
    height: "20px",
    margin: "20px 0",
  },
  loadingMore: {
    textAlign: "center",
    padding: "20px",
    color: "#666",
  },
};

export default Search;
