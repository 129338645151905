// src/components/video-card/Footer.web.jsx
import React, { memo } from "react";

export const Footer = memo(
  ({
    title,
    prompt,
    isEditing,
    editedTitle,
    editedPrompt,
    onTitleChange,
    onPromptChange,
    onSave,
  }) => (
    <div style={styles.footer}>
      <div style={styles.titleContainer}>
        {isEditing ? (
          <input
            type="text"
            value={editedTitle}
            onChange={(e) => onTitleChange(e.target.value)}
            style={styles.input}
            placeholder="Edit title"
          />
        ) : (
          <h3 style={styles.title}>{title}</h3>
        )}
      </div>

      <div style={styles.promptContainer}>
        {isEditing ? (
          <textarea
            value={editedPrompt}
            onChange={(e) => onPromptChange(e.target.value)}
            style={styles.textarea}
            placeholder="Edit description"
          />
        ) : (
          <p style={styles.prompt}>{prompt}</p>
        )}
      </div>

      {isEditing && (
        <button
          onClick={onSave}
          style={{
            ...styles.saveButton,
            ...(editedTitle.trim() && editedPrompt.trim()
              ? {}
              : styles.saveButtonDisabled),
          }}
          disabled={!editedTitle.trim() || !editedPrompt.trim()}
        >
          Save Changes
        </button>
      )}
    </div>
  )
);

const styles = {
  footer: {
    marginTop: "16px",
  },
  titleContainer: {
    marginBottom: "8px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    margin: 0,
  },
  promptContainer: {
    marginBottom: "16px",
  },
  prompt: {
    fontSize: "14px",
    color: "#666",
    margin: 0,
  },
  input: {
    width: "100%",
    padding: "8px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    marginBottom: "8px",
  },
  textarea: {
    width: "100%",
    padding: "8px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    resize: "vertical",
  },
  saveButton: {
    padding: "10px 16px",
    backgroundColor: "#6366F1",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
  },
  saveButtonDisabled: {
    backgroundColor: "#ccc",
    cursor: "not-allowed",
  },
};
