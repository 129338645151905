import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { saveDonationData } from "../lib/firebase.web";
import BurgerMenu from "../components/BurgerMenu";

const Sponsorships = () => {
  // Add state inside the component
  const [donationAmount, setDonationAmount] = useState("");
  const [selectedPresetAmount, setSelectedPresetAmount] = useState(null);
  const navigate = useNavigate();

  // Add these functions inside the component
  const handlePresetAmountSelect = (amount) => {
    setSelectedPresetAmount(amount);
    setDonationAmount(amount);
  };

  const handleCustomAmountChange = (e) => {
    setSelectedPresetAmount(null);
    setDonationAmount(e.target.value.replace(/[^0-9]/g, ""));
  };

  const handleSponsorshipSelect = async (pkg) => {
    try {
      // Set amount based on package price
      let amount = 0;
      let scholarships = 0;

      switch (pkg.id) {
        case "rookie":
          amount = 1000;
          scholarships = 3;
          break;
        case "allstar":
          amount = 2500;
          scholarships = 7;
          break;
        case "mvp":
          amount = 5000;
          scholarships = 12;
          break;
        case "championship":
          amount = 10000;
          scholarships = 20;
          break;
        default:
          console.error("Invalid package ID:", pkg.id);
          return;
      }

      // Create metadata for the sponsorship
      const metadata = {
        packageName: pkg.name,
        packageId: pkg.id,
        scholarships: scholarships,
        purpose: "Basketball Camp Sponsorship",
      };

      // Save sponsorship data to Firestore
      const donationId = await saveDonationData(
        amount,
        "sponsorship",
        metadata
      );

      // Navigate to checkout page
      navigate("/checkout", {
        state: {
          donationId: donationId,
          amount: amount,
          type: "sponsorship",
        },
      });
    } catch (error) {
      console.error("Error creating sponsorship:", error);
      alert("Failed to process sponsorship. Please try again.");
    }
  };

  const handleDonationSubmit = async (e) => {
    e.preventDefault();
    if (!donationAmount || isNaN(donationAmount)) {
      alert("Please enter a valid donation amount.");
      return;
    }

    try {
      const amount = parseInt(donationAmount);
      const type = "donation";
      const metadata = {
        purpose: "Basketball Camp Scholarship Fund",
      };

      const donationId = await saveDonationData(amount, type, metadata);

      navigate("/checkout", {
        state: {
          donationId: donationId,
          amount: amount, // Pass the amount as well
          type: type,
        },
      });
    } catch (error) {
      console.error("Error saving donation data:", error);
      alert("Failed to create donation. Please try again.");
    }
  };

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
  };

  const slideUp = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.6 } },
  };

  // Sponsorship package details
  const packages = [
    {
      id: "rookie",
      name: "Rookie Level",
      price: "$1,000",
      benefits: [
        "3 scholarships for underprivileged youth",
        "Logo/name on camp website with link",
        "Recognition in all email communications",
        "Social media shoutout (2 dedicated posts)",
        "Small logo on camp t-shirts",
      ],
    },
    {
      id: "allstar",
      name: "All-Star Level",
      price: "$2,500",
      popular: true,
      benefits: [
        "7 scholarships for underprivileged youth",
        "Medium logo on camp jerseys",
        "Booth space during family day",
        "Branded items in camper welcome packs",
        "Banner display during camp sessions",
        "Featured in camp highlight video",
        "All benefits from Rookie level",
      ],
    },
    {
      id: "mvp",
      name: "MVP Level",
      price: "$5,000",
      benefits: [
        "12 scholarships for underprivileged youth",
        "Primary logo placement on jerseys and banners",
        "News publication and TV promotion mentions",
        "Named sponsorship of one camp activity",
        "Speaking opportunity at opening ceremony",
        "First right of refusal for next year's sponsorship",
        "Professional photos with camp participants",
        "All benefits from All-Star level",
      ],
    },
    {
      id: "championship",
      name: "Championship Level",
      price: "$10,000",
      benefits: [
        "20 scholarships for underprivileged youth",
        '"Presented by [Sponsor Name]" on all materials',
        "Exclusive naming rights for camp or main court",
        "VIP access to all camp events",
        "Custom promotional video featuring your brand",
        "All benefits from MVP level",
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="container mx-auto px-4 py-4 flex justify-end">
        <BurgerMenu />
      </div>
      <section className="relative h-[50vh] bg-gradient-to-r from-orange-500 to-red-600 flex items-center justify-center">
        <div className="absolute inset-0 bg-black opacity-30"></div>
        <div className="container mx-auto px-6 relative z-10 text-center">
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            className="text-5xl font-bold text-white mb-6"
          >
            Sponsor Our Basketball Camp
          </motion.h1>
          <motion.p
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            className="text-xl text-white mb-10 max-w-3xl mx-auto"
          >
            Partner with us to make a difference in young athletes' lives while
            gaining valuable exposure for your brand.
          </motion.p>
        </div>
      </section>
      {/* Why Sponsor Section */}
      <section className="py-16">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Why Sponsor Our Camp?
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Community Impact",
                description:
                  "Your sponsorship directly funds scholarships for underprivileged youth, making the camp accessible to all families regardless of financial circumstances.",
                icon: "❤️",
              },
              {
                title: "Brand Visibility",
                description:
                  "Gain exposure to hundreds of local families, our online audience, and potential media coverage through strategically placed branding opportunities.",
                icon: "👁️",
              },
              {
                title: "Business Growth",
                description:
                  "Connect with potential customers while demonstrating your business's commitment to youth development and community involvement.",
                icon: "📈",
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={slideUp}
                className="bg-white p-8 rounded-lg shadow-md"
              >
                <div className="text-5xl mb-4">{item.icon}</div>
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {item.title}
                </h3>
                <p className="text-gray-600">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      {/* Sponsorship Packages */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Sponsorship Packages
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
            <p className="mt-4 text-gray-600 max-w-3xl mx-auto">
              Choose the sponsorship level that best fits your organization's
              goals and budget.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
            {packages.map((pkg, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={slideUp}
                className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col h-full relative"
              >
                {pkg.popular && (
                  <div className="absolute top-0 right-0 bg-orange-500 text-white px-3 py-1 text-sm font-bold">
                    Popular
                  </div>
                )}
                <div
                  className={`py-6 px-6 text-center ${
                    pkg.id === "championship"
                      ? "bg-yellow-100"
                      : pkg.id === "mvp"
                      ? "bg-purple-100"
                      : pkg.id === "allstar"
                      ? "bg-green-100"
                      : "bg-blue-100"
                  }`}
                >
                  <h3 className="text-2xl font-bold text-gray-800">
                    {pkg.name}
                  </h3>
                  <p className="text-3xl font-bold text-orange-600 mt-2">
                    {pkg.price}
                  </p>
                </div>
                <div className="p-6 flex-grow">
                  <ul className="space-y-3">
                    {pkg.benefits.map((benefit, i) => (
                      <li key={i} className="flex items-start">
                        <svg
                          className="h-5 w-5 text-green-500 mr-2 mt-0.5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                        <span className="text-gray-600">{benefit}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="p-6 bg-gray-50">
                  <button
                    onClick={() => handleSponsorshipSelect(pkg)}
                    className="w-full bg-orange-500 text-white font-bold py-3 rounded-lg hover:bg-orange-600 transition duration-300"
                  >
                    Select Package
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      <section id="donation" className="py-16">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
            <div className="md:flex">
              <div className="md:w-1/2 bg-orange-500 p-8 text-white">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={fadeIn}
                >
                  <h3 className="text-2xl font-bold mb-4">Make a Donation</h3>
                  <p className="mb-6">
                    Can't commit to a full sponsorship? Consider making a
                    donation to support our scholarship fund for underprivileged
                    youth.
                  </p>
                  <div className="mb-6">
                    <div className="font-bold mb-2">Your Impact:</div>
                    <p>
                      Each $350 donation provides one full scholarship for a
                      child to attend our camp.
                    </p>
                  </div>
                  <div className="w-full bg-white h-4 rounded-full overflow-hidden">
                    <div
                      className="bg-green-500 h-full"
                      style={{ width: "65%" }}
                    ></div>
                  </div>
                  <div className="flex justify-between mt-2 text-sm">
                    <span>Current: 23 scholarships</span>
                    <span>Goal: 35 scholarships</span>
                  </div>
                </motion.div>
              </div>
              <div className="p-8 md:w-1/2">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={slideUp}
                >
                  <h3 className="text-xl font-bold text-gray-800 mb-6">
                    Select Donation Amount
                  </h3>
                  <div className="grid grid-cols-2 gap-4 mb-6">
                    {["25", "50", "100", "250"].map((amount, i) => (
                      <button
                        key={i}
                        type="button"
                        onClick={() => handlePresetAmountSelect(amount)}
                        className={`py-2 border-2 ${
                          selectedPresetAmount === amount
                            ? "border-orange-500 bg-orange-50"
                            : "border-gray-300 hover:bg-orange-50 hover:border-orange-500"
                        } rounded-lg transition duration-300`}
                      >
                        ${amount}
                      </button>
                    ))}
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-700 mb-2">
                      Custom Amount
                    </label>
                    <input
                      type="text"
                      placeholder="Enter amount"
                      value={selectedPresetAmount ? "" : donationAmount}
                      onChange={handleCustomAmountChange}
                      className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                  </div>

                  <button
                    onClick={handleDonationSubmit}
                    className={`w-full ${
                      !donationAmount
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-orange-500 hover:bg-orange-600"
                    } text-white font-bold py-3 rounded-lg transition duration-300`}
                    disabled={!donationAmount}
                  >
                    Donate Now
                  </button>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Contact Us About Sponsorships
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
            <p className="mt-4 text-gray-600 max-w-3xl mx-auto">
              Have questions about our sponsorship opportunities? Our team is
              ready to discuss custom packages tailored to your specific needs.
            </p>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={slideUp}
            className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-md"
          >
            <form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div>
                  <label className="block text-gray-700 mb-2">Name</label>
                  <input
                    type="text"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-2">Company</label>
                  <input
                    type="text"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-2">Email</label>
                  <input
                    type="email"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-2">Phone</label>
                  <input
                    type="tel"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 mb-2">
                  Interested In
                </label>
                <select className="w-full p-3 border border-gray-300 rounded-lg">
                  <option>Rookie Level ($1,000)</option>
                  <option>All-Star Level ($2,500)</option>
                  <option>MVP Level ($5,000)</option>
                  <option>Championship Level ($10,000)</option>
                  <option>Custom Package</option>
                </select>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 mb-2">Message</label>
                <textarea className="w-full p-3 border border-gray-300 rounded-lg h-32"></textarea>
              </div>
              <button className="bg-orange-500 text-white font-bold py-3 px-8 rounded-lg hover:bg-orange-600 transition duration-300">
                Send Inquiry
              </button>
            </form>
          </motion.div>
        </div>
      </section>
      {/* FAQ Section */}
      <section className="py-16">
        <div className="container mx-auto px-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Frequently Asked Questions
            </h2>
            <div className="w-20 h-1 bg-orange-500 mx-auto"></div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={slideUp}
            className="max-w-4xl mx-auto"
          >
            {[
              {
                question: "When is the deadline to become a sponsor?",
                answer:
                  "For maximum exposure, we recommend committing to sponsorship at least 4 weeks before the camp begins. This ensures your brand is included in all pre-camp promotional materials. However, we can accommodate sponsors up to 1 week before camp starts.",
              },
              {
                question: "Can we customize our sponsorship package?",
                answer:
                  "Absolutely! We understand that different businesses have different goals. We're happy to work with you to create a custom sponsorship package that aligns with your specific objectives and budget.",
              },
              {
                question: "How are the scholarship recipients selected?",
                answer:
                  "Scholarship recipients are selected through an application process that evaluates financial need, demonstrated interest in basketball, and commitment to teamwork and sportsmanship. Your sponsorship directly helps these deserving young athletes.",
              },
              {
                question: "What marketing materials do we need to provide?",
                answer:
                  "For optimal representation, we request high-resolution logos (vector format preferred), brand guidelines, and any specific messaging you'd like included. Our team will handle the proper placement and integration of your branding materials.",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="mb-6 bg-white p-6 rounded-lg shadow-md"
              >
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {item.question}
                </h3>
                <p className="text-gray-600">{item.answer}</p>
              </div>
            ))}
          </motion.div>
        </div>
      </section>
      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-orange-500 to-red-600 text-white">
        <div className="container mx-auto px-6 text-center">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-4xl font-bold mb-6"
          >
            Ready to Make a Difference?
          </motion.h2>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
            className="text-xl mb-8 max-w-2xl mx-auto"
          >
            Join us in supporting youth basketball development while promoting
            your brand to our community.
          </motion.p>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={slideUp}
          >
            {/* <button className="bg-white text-orange-600 font-bold py-4 px-10 rounded-full text-lg hover:bg-orange-100 transition duration-300 inline-block mx-2">
              Become a Sponsor
            </button> */}
            <button className="bg-transparent border-2 border-white text-white font-bold py-4 px-10 rounded-full text-lg hover:bg-white hover:bg-opacity-10 transition duration-300 inline-block mx-2">
              Contact Us
            </button>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Sponsorships;
