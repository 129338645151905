import React from "react";
import { Loader2 } from "lucide-react";

const LoadingScreen = ({ message = "Loading... Please wait" }) => {
  return (
    <div className="fixed inset-0 bg-white bg-opacity-90 backdrop-blur-sm flex flex-col items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center">
        <Loader2 className="w-12 h-12 text-blue-600 animate-spin mb-4" />
        <p className="text-gray-700 text-lg font-medium">{message}</p>
        <div className="mt-4 w-48 h-2 bg-gray-200 rounded-full overflow-hidden">
          <div className="h-full bg-blue-600 rounded-full animate-progress"></div>
        </div>
      </div>
    </div>
  );
};

// You can also export a smaller inline version for use within components
export const LoadingSpinner = ({ size = "md", white = false }) => {
  const sizeClasses = {
    sm: "w-4 h-4",
    md: "w-6 h-6",
    lg: "w-8 h-8",
    xl: "w-12 h-12",
  };

  return (
    <Loader2
      className={`
        ${sizeClasses[size]} 
        ${white ? "text-white" : "text-blue-600"} 
        animate-spin
      `}
    />
  );
};

// Mini loading component for buttons
export const ButtonLoader = () => {
  return (
    <div className="flex items-center space-x-2">
      <LoadingSpinner size="sm" white />
      <span>Loading...</span>
    </div>
  );
};

export default LoadingScreen;
