// src/app/(tabs)/home.web.jsx
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/FirebaseGlobalProvider";
import VideoCard from "../components/mediaComponents/VideoCard.Web";
import EmptyHomeState from "../components/HomeEmptyState.web";
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  addDoc, // Add this
  serverTimestamp, // Add this
} from "firebase/firestore";
import { images } from "../constants";

const POSTS_PER_PAGE = 100;

const Home = () => {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const [allPosts, setAllPosts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [isGloballyMuted, setIsGloballyMuted] = useState(true);
  const [visibleItems, setVisibleItems] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [cachedPosts, setCachedPosts] = useState({});

  const listRef = useRef(null);
  const observer = useRef(null);
  const db = getFirestore();

  useEffect(() => {
    console.log("useEffect triggered with user:", user?.uid);
    if (user?.uid) {
      // Changed from $id to uid
      loadInitialPosts();
    } else {
      setIsLoading(false); // Set loading to false if no user
      setAllPosts([]);
    }

    // Setup Intersection Observer for infinite scroll
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingMore) {
          loadMorePosts();
        }
      },
      { threshold: 0.5 }
    );

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [user]);

  const fetchPosts = async (isInitial = false) => {
    try {
      // First, check if user exists and log the user object to debug
      console.log("Current user:", user);

      if (!user?.uid) {
        // Changed from $id to uid
        console.log("No user logged in");
        return { posts: [], hasMore: false };
      }

      // Get following list - update the collection path
      const followingSnapshot = await getDocs(
        collection(db, "users", user.uid, "following") // Changed from $id to uid
      );

      console.log(
        "Following snapshot:",
        followingSnapshot.docs.map((doc) => doc.id)
      );

      let followedUserIds = followingSnapshot.docs.map((doc) => doc.id);
      followedUserIds.push(user.uid); // Include user's own posts

      // Log the query parameters
      console.log("Querying posts for users:", followedUserIds);

      let baseQuery = query(
        collection(db, "videos"),
        where("creator", "in", followedUserIds),
        orderBy("createdAt", "desc"),
        limit(POSTS_PER_PAGE)
      );

      const snapshot = await getDocs(baseQuery);
      console.log("Posts snapshot size:", snapshot.size);

      if (snapshot.empty) {
        console.log("No posts found");
        return { posts: [], hasMore: false };
      }

      const posts = await Promise.all(
        snapshot.docs.map(async (document) => {
          const postData = document.data();
          const creatorDoc = await getDoc(doc(db, "users", postData.creator));
          const creatorData = creatorDoc.data();

          return {
            $id: document.id,
            ...postData,
            creator: {
              $id: postData.creator,
              ...creatorData,
            },
          };
        })
      );

      console.log("Processed posts:", posts.length);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      return { posts, hasMore: posts.length === POSTS_PER_PAGE };
    } catch (error) {
      console.error("Error fetching posts:", error);
      console.error("Error details:", error.message);
      return { posts: [], hasMore: false };
    }
  };

  const loadInitialPosts = async () => {
    console.log("Starting loadInitialPosts...");
    setIsLoading(true);
    try {
      const { posts } = await fetchPosts(true);
      console.log("Posts received in loadInitialPosts:", posts);

      if (Array.isArray(posts)) {
        setAllPosts(posts);
        console.log("Posts set to state:", posts.length);
        setCachedPosts((prev) => ({
          ...prev,
          [user?.uid]: posts, // Changed from $id to uid
        }));
      } else {
        console.error("Posts is not an array:", posts);
        setAllPosts([]);
      }
    } catch (error) {
      console.error("Error loading posts:", error);
      setAllPosts([]);
    } finally {
      console.log("Setting isLoading to false");
      setIsLoading(false);
    }
  };

  const loadMorePosts = async () => {
    if (isLoadingMore) return;

    setIsLoadingMore(true);
    try {
      const { posts } = await fetchPosts(false);
      setAllPosts((prevPosts) => {
        const newPosts = posts.filter(
          (newPost) =>
            !prevPosts.some((existingPost) => existingPost.$id === newPost.$id)
        );
        return [...prevPosts, ...newPosts];
      });
    } catch (error) {
      console.error("Error loading more posts:", error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      await deleteDoc(doc(db, "videos", postId));
      setAllPosts((prevPosts) =>
        prevPosts.filter((post) => post.$id !== postId)
      );
    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  const updatePostDetails = async (postId, updates) => {
    try {
      await updateDoc(doc(db, "videos", postId), updates);
      setAllPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.$id === postId ? { ...post, ...updates } : post
        )
      );
      return true;
    } catch (error) {
      console.error("Update error:", error);
      return false;
    }
  };

  // Add this function to home.web.jsx
  const handleReportPost = async (postId) => {
    try {
      await addDoc(collection(db, "reports"), {
        postId,
        reportedBy: user.uid,
        createdAt: serverTimestamp(),
      });

      alert("Post reported successfully.");
    } catch (error) {
      console.error("Report error:", error);
      alert("Failed to report post.");
    }
  };

  const spinKeyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>CREATOR CONNECT</h1>
        <button
          style={styles.aiButton}
          onClick={() => navigate("/influencers")}
        >
          <span style={styles.aiButtonIcon}>🤖</span>
          <span style={styles.aiButtonText}>DATA</span>
        </button>
      </div>

      {isLoading ? (
        <div style={styles.loadingContainer}>
          <div style={styles.spinner} />
        </div>
      ) : allPosts.length === 0 ? (
        <EmptyHomeState onSearchPress={() => navigate("/tabs/search")} />
      ) : (
        <div style={styles.postsContainer}>
          {console.log("About to render posts:", allPosts)}

          {allPosts.map((post, index) => {
            // console.log("Post details:", {
            //   id: post.$id,
            //   media: post.media,
            //   mediaType: post.mediaType,
            //   title: post.title,
            // });
            return (
              <VideoCard
                key={post.$id}
                title={post.title || ""}
                creator={post.creator?.username || "Unknown Creator"}
                prompt={post.prompt || ""}
                avatar={post.creator?.avatar || "/default-avatar.png"}
                media={post.media}
                mediaType={post.mediaType}
                category={post.category}
                currentUserId={user?.uid}
                creatorId={post.creator?.$id}
                postId={post.$id}
                cost={post.cost}
                budget={post.budget}
                currency={post.currency}
                onAvatarPress={() =>
                  navigate(
                    post.creator?.$id === user?.uid
                      ? "/tabs/profile"
                      : `/user-profile/${post.creator?.$id}`
                  )
                }
                onDeletePress={() => handleDeletePost(post.$id)}
                onReportPress={() => handleReportPost(post.$id)}
                showDelete={user && post.creator?.$id === user.uid}
                isGloballyMuted={isGloballyMuted}
                onMuteChange={setIsGloballyMuted}
                isViewable={true}
                updatePostDetails={updatePostDetails}
              />
            );
          })}
          {console.log("Render state:", {
            isLoading,
            postsLength: allPosts.length,
          })}

          {isLoadingMore && (
            <div style={styles.loadingMore}>
              <div style={styles.spinner} />
            </div>
          )}
          <div
            ref={(el) => {
              if (el) {
                observer.current.observe(el);
              }
            }}
            style={styles.loadingTrigger}
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#fff",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
  },
  title: {
    fontSize: "24px",
    fontFamily: "Oswald-Medium, sans-serif",
    margin: 0,
  },
  aiButton: {
    backgroundColor: "#6366F1",
    padding: "8px 16px",
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "white",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  aiButtonIcon: {
    fontSize: "20px",
  },
  aiButtonText: {
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Oswald-Medium, sans-serif",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
  },
  postsContainer: {
    display: "grid", // Change from flex to grid
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", // Responsive grid
    gap: "1.5rem",
    padding: "1rem 0",
    minHeight: "100px",
  },
  loadingMore: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
  spinner: {
    width: "40px",
    height: "40px",
    border: "4px solid #f3f3f3",
    borderTop: "4px solid #6366F1",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  loadingTrigger: {
    height: "20px",
    margin: "20px 0",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

export default Home;
