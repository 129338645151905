import React, { useState } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

const EmailSignupSection = () => {
  const [form, setForm] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage({ type: "", text: "" });

    try {
      const db = getFirestore();

      // Create a new document in the users collection
      await addDoc(collection(db, "users"), {
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        createdAt: serverTimestamp(),
        // Email trigger data
        to: form.email,
        message: {
          subject: "Welcome to Creator Connect",
          html: `
            <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
              <h1>Welcome to Creator Connect!</h1>
              <p>Hi ${form.firstName},</p>
              <p>Thank you for joining our email list. We're excited to have you as part of our creator community!</p>
              <p>We'll keep you updated with the latest news, features, and tips to help you grow your creative business.</p>
              <div style="margin: 30px 0;">
                <p>Best regards,</p>
                <p>The Creator Connect Team</p>
              </div>
            </div>
          `,
        },
      });

      setMessage({
        type: "success",
        text: "Thanks for signing up! Please check your email for a welcome message.",
      });
      setForm({ email: "", firstName: "", lastName: "" });
    } catch (error) {
      console.error("Error signing up:", error);
      setMessage({
        type: "error",
        text: "Something went wrong. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative bg-gradient-to-b from-gray-50 to-white py-16">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-40 -right-32 w-96 h-96 bg-pink-100 rounded-full opacity-20 blur-3xl" />
        <div className="absolute -bottom-40 -left-32 w-96 h-96 bg-blue-100 rounded-full opacity-20 blur-3xl" />
      </div>

      <div className="relative container mx-auto px-4">
        <div className="max-w-2xl mx-auto text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 font-oswald">
            Join Our Creator Community
          </h2>
          <p className="text-lg text-gray-600">
            Sign up to get early access and exclusive updates about our
            platform.
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="max-w-xl mx-auto bg-white rounded-2xl shadow-xl p-8 space-y-6"
        >
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                value={form.firstName}
                onChange={(e) =>
                  setForm((prev) => ({ ...prev, firstName: e.target.value }))
                }
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-[#DC2D5A] focus:border-transparent transition-colors"
                required
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                value={form.lastName}
                onChange={(e) =>
                  setForm((prev) => ({ ...prev, lastName: e.target.value }))
                }
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-[#DC2D5A] focus:border-transparent transition-colors"
                required
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={form.email}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, email: e.target.value }))
              }
              className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-[#DC2D5A] focus:border-transparent transition-colors"
              required
            />
          </div>

          {message.text && (
            <div
              className={`p-4 rounded-lg ${
                message.type === "success"
                  ? "bg-green-50 text-green-800"
                  : "bg-red-50 text-red-800"
              }`}
            >
              {message.text}
            </div>
          )}

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-[#DC2D5A] text-white py-4 rounded-lg font-semibold hover:bg-[#DC2D5A]/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed font-oswald"
          >
            {isSubmitting ? "Signing Up..." : "Join the Community"}
          </button>

          <p className="text-sm text-center text-gray-500">
            By signing up, you agree to our{" "}
            <a href="#" className="text-[#DC2D5A] hover:underline">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="#" className="text-[#DC2D5A] hover:underline">
              Privacy Policy
            </a>
          </p>
        </form>
        {/* Divider with text */}
        <div className="max-w-xl mx-auto mt-12 flex items-center gap-4">
          <div className="flex-1 h-px bg-gray-200"></div>
          <span className="text-gray-500 font-medium px-4">Or</span>
          <div className="flex-1 h-px bg-gray-200"></div>
        </div>

        {/* App Store direct download section */}
        <div className="max-w-xl mx-auto mt-8 text-center">
          <p className="text-lg text-gray-600 mb-6">
            Prefer to get started right away? Download our app directly:
          </p>
          <div className="flex justify-center">
            <a
              href="https://itunes.apple.com/us/app/creator-connect/id1419740204?mt=8"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-all hover:scale-105 hover:-translate-y-1 duration-300"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/ccfirebasetest.firebasestorage.app/o/videos%2FDownload_on_the_App_Store_Badge.svg.png?alt=media&token=6f0b0522-7ce4-4a02-9a16-5ca1bbec23e5"
                alt="Download on App Store"
                className="h-14"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSignupSection;
