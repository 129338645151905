// src/app/(auth)/forgot-password.web.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../lib/firebase.web";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      alert("Please enter your email address");
      return;
    }

    setIsSubmitting(true);

    try {
      await resetPassword(email);
      alert("Password reset email sent. Please check your inbox.");
      navigate("/sign-in");
    } catch (error) {
      alert(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>RESET PASSWORD</h1>
      <p style={styles.description}>
        Enter your email address and we'll send you instructions to reset your
        password.
      </p>

      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formField}>
          <label style={styles.label}>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
            autoCapitalize="none"
          />
        </div>

        <button type="submit" style={styles.button} disabled={isSubmitting}>
          {isSubmitting ? "Sending..." : "Reset Password"}
        </button>

        <button
          type="button"
          onClick={() => navigate("/sign-in")}
          style={styles.backButton}
        >
          Back to Sign In
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    maxWidth: "400px",
    margin: "0 auto",
    minHeight: "100vh",
    backgroundColor: "white",
  },
  header: {
    fontSize: "2rem",
    fontFamily: "Oswald-Medium, sans-serif",
    marginBottom: "1.25rem",
    color: "#333",
    width: "100%",
    textAlign: "left",
  },
  description: {
    fontSize: "1rem",
    color: "#666",
    marginBottom: "1.5rem",
    width: "100%",
    textAlign: "left",
  },
  form: {
    width: "100%",
  },
  formField: {
    marginBottom: "1.25rem",
    width: "100%",
  },
  label: {
    display: "block",
    marginBottom: "0.5rem",
    fontSize: "1rem",
    color: "#333",
  },
  input: {
    width: "100%",
    padding: "0.75rem",
    fontSize: "1rem",
    border: "1px solid #ddd",
    borderRadius: "4px",
  },
  button: {
    width: "100%",
    padding: "0.75rem",
    fontSize: "1rem",
    backgroundColor: "#222528",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "1rem",
  },
  backButton: {
    width: "100%",
    padding: "0.75rem",
    fontSize: "1rem",
    backgroundColor: "transparent",
    color: "#DC2D5A",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
  },
};

export default ForgotPassword;
